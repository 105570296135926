import ActivityService, {
    ActivityDirection,
    ActivityItem,
    ActivityState,
    activityTypeOptions,
} from '../../services/activity'
import InputField from '../Input/InputField'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Selector } from '../Input/Selector'
import { InfoIcon } from '../Icons/InfoIcon'
import { Link } from 'react-router-dom'
import DataRequestService from '../../services/dataRequest'
import Utils from '../../services/utils'
import { OrgSelector } from '../Org/OrgSelector'
import { ScopeSelector } from '../Input/ScopeSelector'
import CO2e from '../CO2e'
import Delete from '../Delete'
import { ActivityInputItem } from './ActivityInputItem'
import { CompanyFilter } from '../Company/CompanyFilter'
import { ActivityActionType, ActivityContext } from '../../services/activityContext'
import ProductService from '../../services/product'
import { Company } from '../../services/company'
import { InventoryActionType, InventoryService } from '../../services/inventory'
import { BatchSelector } from '../Input/BatchSelector'
import { ApplicationContext } from '../../context'
import { VariableServicesContext } from '../../services'
import { ActivityStateManager } from './ActivityStateManager'
import { ActivityOnboarding } from './ActivityOnboarding'
import { CalendarDots, Pencil, Plug, Warning } from '@phosphor-icons/react'
import { DataRequestContext } from '../../services/dataRequestContext'
import { Footnotes } from '../Footnotes'
import { Dayjs } from 'dayjs'
import GHGService from '../../services/ghg'
import FlagService, { FlagType } from '../../services/flag'
import { GoOptions } from './GoOptions'
import { useSave } from '../../hooks/useSave'
import ElectricityService from '../../services/electricity'
import { RenewableProductionEditor } from './RenewableProductionEditor'
import { DateRange } from '../Input/DateRange'
import { AmountInput } from '../Input/Amount'
import UnitService, { UnitType } from '../../services/unit'
import { FileManager } from '../Input/FileUploader'
import Tooltip from '../Tooltip'
import { PrettyNumber } from '../PrettyNumber'
import { ND } from '../../types'

let dateTimer: NodeJS.Timeout | undefined = undefined

export const ActivityDetails = (props: {
    activityItem: ActivityItem
    hideApprovalActions?: boolean
    onRemove?: (activityItem: ActivityItem) => void
}) => {
    const context = useContext(ApplicationContext)
    const activityContext = useContext(ActivityContext)
    const drContext = useContext(DataRequestContext)
    const { activityService, analyticsService } = useContext(VariableServicesContext)
    const [activityItem, setActivityItem] = useState<ActivityItem>(props.activityItem)
    const [startDate, setStartDate] = useState<Date>()
    const [endDate, setEndDate] = useState<Date>()
    const co2eRef = useRef<any>()
    const quantityRef = useRef<any>()

    useEffect(() => setActivityItem(props.activityItem), [props.activityItem])

    const perspective = useMemo(() => activityService.getActivityPerspective(activityItem), [activityItem])
    const disabled = useMemo(() => !activityService.isEditable(activityItem), [activityItem.state])
    const isElectricity = useMemo(
        () => ElectricityService.isElectricity(activityItem.product, activityItem?.taxonomy) && perspective.incoming,
        [activityItem?.taxonomy?.path, perspective.incoming, context.stores.ui?.flagsReady],
    )
    const co2eValue = useMemo(
        () => (drContext.dataRequest?.uuid ? activityItem.impact?.gwp.total.up : perspective.co2e),
        [drContext.dataRequest?.uuid, activityItem.impact?.gwp.total.up, perspective.co2e],
    )

    const hasCo2eValue = useMemo(
        () =>
            (activityItem.quantity && (activityItem.product || activityItem.transportType)) || activityItem.staticData,
        [activityItem.quantity, activityItem.product, activityItem.transportType, activityItem.staticData],
    )

    const instanceId = useMemo(() => `activity-${activityItem?.uuid || Math.random()}`, [activityItem?.uuid])

    const partialSaveFn = useCallback(
        async (activityProperties: Partial<ActivityItem>) => {
            const uuid = activityItem.uuid || activityProperties.uuid
            setActivityItem((prev) => ({ ...prev, ...activityProperties }))
            return activityService
                .createOrUpdate({ dataRequest: drContext.dataRequest, ...activityProperties })
                .then((_activity) => {
                    if (perspective?.incoming && _activity.product?.uuid) {
                        context.dispatch({
                            type: InventoryActionType.Merge,
                            payload: [InventoryService.productToInventory(_activity.product)],
                        })
                    }
                    if (!uuid) {
                        activityContext.dispatch({ type: ActivityActionType.UpdateList })
                        analyticsService.trackActivityCreated({
                            onboarding: activityContext.isOnboarding,
                            dataRequest: drContext.dataRequest?.uuid,
                            dataRequestActivityCount: drContext.dataRequest?.activities?.length,
                            dataRequestPerspective: drContext.drPerspective
                                ? drContext.drPerspective?.isSupplier
                                    ? 'supplier'
                                    : 'customer'
                                : undefined,
                        })
                    }
                    activityContext.dispatch({ type: ActivityActionType.Update, payload: _activity })
                    return _activity
                })
        },
        [activityItem, perspective?.incoming, activityContext.isOnboarding, drContext.dataRequest],
    )

    const { pSave, saving } = useSave({ instanceId, node: activityItem, partialSaveFn })

    useEffect(() => {
        const sd =
            activityItem.startDate || drContext.dataRequest?.startDate || activityItem.endDate || activityItem.date
        const ed = activityItem.endDate || drContext.dataRequest?.endDate || activityItem.date
        if (!startDate && sd) setStartDate(new Date(sd))
        if (!endDate && ed) setEndDate(new Date(ed))
    }, [])

    const setStartEndDate = useCallback(
        (sd: Dayjs, ed: Dayjs, immediately: boolean = false) => {
            if (dateTimer) clearTimeout(dateTimer)
            setStartDate(sd.toDate())
            setEndDate(ed.toDate())
            dateTimer = setTimeout(
                () => pSave({ startDate: sd.toDate().valueOf(), endDate: ed.toDate().valueOf() }),
                immediately ? 1 : 300,
            )
        },
        [activityItem?.uuid],
    )

    const dataRequestInfo = useMemo(() => {
        if (!activityItem.dataRequest?.uuid) return null
        return (
            <InfoIcon
                size={Utils.tinyIconSize}
                interactive={true}
                className='ms-1'
                color={Utils.fadedColor}
                tooltipProps={{ tooltipStyle: { maxWidth: '22rem' } }}
                tooltip={
                    <span className='small'>
                        This field cannot be changed because this activity is part of the Request:{' '}
                        <Link
                            className='text-decoration-underline mt-1'
                            to={DataRequestService.getDataRequestUrl(activityItem.dataRequest)}
                        >
                            {activityItem.dataRequest?.name}
                        </Link>
                    </span>
                }
            />
        )
    }, [activityItem.dataRequest?.uuid])

    // const co2eIntensity = useMemo(() => {
    //     if (activityItem.staticData || !activityItem.product?.unit?.code) return null
    //     if (perspective.incoming && !activityItem.impact?.gwp.intensity.up) return null
    //     if (perspective.outgoing && !activityItem.impact?.gwp.intensity.down) return null
    //     if (isElectricity) return null
    //     return (
    //         <div className='row align-items-center'>
    //             <div className='col col-lg-4 small'>{Utils.co2e} intensity</div>
    //             <div className='col px-3 small'>
    //                 <CO2e
    //                     co2e={
    //                         perspective.incoming || drContext.dataRequest?.uuid || activityItem.batch?.uuid
    //                             ? activityItem.impact?.gwp.intensity.up
    //                             : activityItem.impact?.gwp.intensity.down
    //                     }
    //                     functionalUnit={activityItem.product?.unit?.code}
    //                     functionalUnitClassName='ms-1'
    //                     unitSize='small'
    //                 />
    //             </div>
    //         </div>
    //     )
    // }, [
    //     isElectricity,
    //     activityItem.staticData,
    //     activityItem.product?.unit?.code,
    //     activityItem.batch?.uuid,
    //     perspective.incoming,
    //     perspective.outgoing,
    //     drContext.dataRequest?.uuid,
    //     activityItem.impact,
    // ])

    const typeSelector = useMemo(
        () => (
            <Selector
                placeholder='Select type...'
                className='variable-form-select active d-inline-block'
                placement='bottom-start'
                hideTextFilter={true}
                disabled={disabled || activityItem.dataRequest?.uuid !== undefined}
                options={activityTypeOptions}
                option={activityTypeOptions.find((ato) => ato.value === perspective.direction)}
                onSelect={(newValue) => {
                    let newProps: Partial<ActivityItem> = {
                        direction: newValue.value,
                        product: null,
                    }
                    if (newValue.value === ActivityDirection.OUTPUT) {
                        newProps.sender = context.stores.company
                        newProps.receiver = null
                        if (!activityItem.scope) {
                            newProps.scope = GHGService.byName.get('use of sold products')
                        }
                    } else {
                        newProps.sender = null
                        newProps.receiver = context.stores.company
                    }
                    pSave({ ...newProps }).then()
                }}
            />
        ),
        [
            activityItem.dataRequest?.uuid,
            activityItem.scope,
            perspective.direction,
            disabled,
            context.stores.company,
            pSave,
        ],
    )

    const inputItem = useMemo(
        () => (
            <ActivityInputItem
                activity={activityItem}
                disabled={disabled}
                onActivityChange={(activityProperties) => pSave(activityProperties)}
                onProduct={(product, _taxonomy) => {
                    let _sender: Company | null = null

                    if (perspective.incoming) {
                        if (product && !ProductService.isEmissionFactor(product)) {
                            _sender = product?.productOf || null
                        }
                    } else {
                        _sender = context.stores.company || null
                    }

                    pSave({
                        product: product,
                        unit: activityService.getActivityUnit(activityItem, product),
                        sender: _sender || null,
                        taxonomy: product?.taxonomy || _taxonomy,
                        transportType: null,
                    }).then()
                    if (product?.uuid) setTimeout(() => quantityRef.current.focus())
                }}
                onTransport={(transportType, taxonomy) => {
                    pSave({
                        transportType: transportType,
                        unit: activityService.getActivityUnit(activityItem, undefined, transportType),
                        taxonomy: transportType?.taxonomy || taxonomy,
                        product: null,
                    }).then()
                    if (transportType?.uuid) setTimeout(() => quantityRef.current.focus())
                }}
                onTaxonomy={(taxonomy) => pSave({ taxonomy })}
                onSupplierChange={(sender) => pSave({ product: activityItem.product, sender })}
            />
        ),
        [activityItem, disabled, perspective.incoming, context.stores.company, quantityRef.current, pSave],
    )

    const dateRange = useMemo(
        () => (
            <div className='d-flex align-items-center ps-2'>
                <DateRange
                    id={`date-range-${activityItem.uuid}`}
                    disabled={disabled}
                    extraClassName='ms--2'
                    minDate={
                        activityContext.dataRequest?.startDate
                            ? new Date(activityContext.dataRequest?.startDate)
                            : undefined
                    }
                    maxDate={
                        activityContext.dataRequest?.endDate
                            ? new Date(activityContext.dataRequest?.endDate)
                            : undefined
                    }
                    datePickerProps={{ showIcon: true, icon: <CalendarDots /> }}
                    inputStyle={{ maxWidth: '7rem' }}
                    startDate={startDate}
                    endDate={endDate}
                    onChanged={(newStartDate, newEndDate) => {
                        const sd = Utils._dayjs(newStartDate).startOf('day')
                        const ed = Utils._dayjs(newEndDate).endOf('day')
                        setStartEndDate(sd, ed)
                    }}
                />
            </div>
        ),
        [
            activityItem.uuid,
            activityItem.dataRequest?.startDate,
            activityItem.dataRequest?.endDate,
            startDate,
            endDate,
            setStartEndDate,
        ],
    )

    const quantity = useMemo(() => {
        const label = isElectricity ? (
            <span className='text-nowrap ps-2 fw-bold small text-muted'>
                <Plug /> Consumed electricity
            </span>
        ) : undefined

        const unit = activityService.getActivityUnit(activityItem, activityItem.product, activityItem.transportType)

        if (!isElectricity && disabled) {
            return (
                <div className='ps-2'>
                    <PrettyNumber num={activityItem.quantity} />{' '}
                    {unit?.type === UnitType.UNKNOWN ? Utils.pluralize(unit?.name, activityItem.quantity) : unit?.code}
                </div>
            )
        }
        return (
            <div className='d-flex align-items-center justify-content-between gap-2'>
                {label}
                <AmountInput
                    extraClassName='small'
                    amount={{ quantity: activityItem?.quantity || undefined, unit }}
                    inputFieldProps={{
                        passedRef: quantityRef,
                        placeholder: 'Quantity',
                        isNumber: true,
                        className: [
                            'variable-form-control bg-light text-primary',
                            isElectricity ? 'text-end' : 'text-center',
                        ].join(' '),
                        style: { width: '6rem' },
                        disabled: disabled || activityItem.calculation?.uuid !== undefined,
                    }}
                    unitSelectorProps={{
                        extraClassName: 'bg-light',
                        placement: 'bottom-end',
                        comparisonUnit: activityItem?.product?.unit,
                        unitType: unit?.type,
                        disabled: disabled || (!unit?.uuid && !activityItem.staticData),
                        style: { width: 'auto' },
                    }}
                    onChange={(amount) => pSave({ quantity: amount?.quantity, unit: amount?.unit })}
                />
            </div>
        )
    }, [
        quantityRef,
        isElectricity,
        activityItem,
        disabled,
        activityItem.calculation?.uuid,
        activityItem.quantity,
        activityItem.unit,
        activityItem.product?.unit,
        activityItem.staticData,
        pSave,
    ])

    const electricityBody = useMemo(() => {
        if (!isElectricity) return null
        return (
            <>
                <RenewableProductionEditor
                    activityItem={activityItem}
                    disabled={disabled}
                    onChange={() => {
                        activityService.getActivity(activityItem.uuid).then((ai) => {
                            activityContext.dispatch({ type: ActivityActionType.Update, payload: ai })
                        })
                    }}
                />
                <GoOptions
                    node={activityItem}
                    disabled={disabled}
                    onChange={() => {
                        activityService.getActivity(activityItem.uuid).then((ai) => {
                            activityContext.dispatch({ type: ActivityActionType.Update, payload: ai })
                        })
                    }}
                />
                <div className='d-flex align-items-stretch gap-2'>
                    <div className='w-50 bg-light rounded-2 p-2 d-flex flex-column'>
                        <span className='small'>{Utils.co2e} (location-based)</span>
                        <CO2e
                            product={activityItem.product}
                            co2e={co2eValue}
                            numberClassName='fw-bold fs-4'
                            unitsClassName='small'
                            unitClassName='fw-bold ms-1'
                            unitSize='large'
                            precision={2}
                            onZero={hasCo2eValue ? undefined : 'dash'}
                            showZeroesToPrecision={true}
                        />
                    </div>
                    <div className='w-50 bg-light rounded-2 p-2 d-flex flex-column'>
                        <span className='small'>{Utils.co2e} (market-based)</span>
                        <CO2e
                            product={activityItem.product}
                            co2e={activityItem.impact?.gwp.market?.up}
                            className='mt-2'
                            // numberClassName='fs-4'
                            unitsClassName='text-muted small'
                            unitSize='large'
                            precision={2}
                            onZero={hasCo2eValue ? undefined : 'dash'}
                            showZeroesToPrecision={true}
                        />
                    </div>
                </div>
            </>
        )
    }, [isElectricity, activityItem, disabled, co2eValue, drContext.dataRequest?.uuid])

    const customerSelector = useMemo(() => {
        if (perspective.incoming) return null
        return (
            <div className='bg-light rounded-2 p-2'>
                <div className='d-flex align-items-center gap-2'>
                    <CompanyFilter
                        extraClassName='ms--2'
                        placement='left-start'
                        perspective={perspective.otherCompanyPerspective}
                        company={perspective.otherCompany}
                        disabled={disabled || activityItem.dataRequest !== undefined}
                        allowCreate={true}
                        onSelect={(receiver) => pSave({ receiver })}
                    />
                    {dataRequestInfo}
                    {!activityItem.dataRequest && (
                        <InfoIcon
                            className='ms-auto nt-1'
                            iconProps={{ className: 'nt--2 me-1', size: Utils.tinyIconSize }}
                            tooltipProps={{ tooltipClassName: 'small', tooltipStyle: { maxWidth: '20rem' } }}
                            tooltip={`This is the amount of embodied emissions you're passing on to your customer.`}
                        >
                            <CO2e
                                co2e={activityItem.impact?.gwp.total.up}
                                numberClassName=' '
                                unitsClassName='small'
                                unitSize='large'
                                onZero={activityItem.impact?.gwp.total.up !== undefined ? undefined : 'dash'}
                                showZeroesToPrecision={true}
                            />
                        </InfoIcon>
                    )}
                </div>
            </div>
        )
    }, [
        disabled,
        dataRequestInfo,
        perspective.incoming,
        perspective.otherCompanyPerspective,
        perspective.otherCompany,
        activityItem.dataRequest,
        activityItem.impact?.gwp,
    ])

    const staticSelector = useMemo(() => {
        if (disabled) return null
        return (
            <Selector
                label={<Pencil weight='regular' className='text-muted' />}
                className='d-inline-block px-1 bg-light-hover rounded-1'
                hideTextFilter={true}
                placement='bottom-start'
                options={[
                    { value: false, name: <>Use calculated {Utils.co2e}</> },
                    { value: true, name: <>Enter total {Utils.co2e}</> },
                ]}
                option={activityItem.staticData}
                onSelect={(newValue) => {
                    const staticData = newValue.value
                    Utils.copyToClipboard(perspective.co2e?.toString())
                    if (staticData) {
                        setTimeout(() => co2eRef.current.focus(), 10)
                    }
                    pSave({ staticData }).then()
                }}
            />
        )
    }, [activityItem.staticData, disabled, pSave, perspective.co2e, co2eRef.current])

    const staticDataEntry = useMemo(
        () => (
            <div className='bg-light rounded-2 p-2'>
                <span className='small'>{Utils.co2e} emissions</span>
                {staticSelector}
                <AmountInput
                    disabled={disabled}
                    amount={{
                        quantity: parseFloat(
                            activityItem.staticCo2e || activityItem.impact?.gwp.total.up?.toString() || '0',
                        ),
                        unit: activityItem.staticUnit || UnitService.unitByCode['kg'],
                    }}
                    inputFieldProps={{
                        passedRef: co2eRef,
                        placeholder: `Total CO2e`,
                        className: 'variable-form-control bg-white text-primary text-center',
                        style: { width: '8rem' },
                        numberPrecision: 8,
                    }}
                    unitSelectorProps={{
                        extraClassName: 'bg-light',
                        valueSuffix: Utils.co2e,
                        unitType: UnitType.WEIGHT,
                        placement: 'bottom-end',
                    }}
                    onChange={(amount) => {
                        if (!amount?.unit?.fromBaseUnit) return
                        pSave({
                            staticCo2e: amount?.quantity?.toString(),
                            staticUnit: amount?.unit,
                        }).then()
                    }}
                />
            </div>
        ),
        [activityItem, disabled, staticSelector, co2eRef, pSave],
    )

    const co2eData = useMemo(() => {
        if (isElectricity) return null
        if (activityItem.staticData && !disabled) {
            return staticDataEntry
        }
        return (
            <div className='w-100 bg-light rounded-2 p-2 d-flex align-items-center gap-2'>
                <div>
                    <span className='small'>{Utils.co2e} emissions</span>
                    {staticSelector}
                    <div>
                        <CO2e
                            product={activityItem.product}
                            co2e={co2eValue}
                            className='fw-bold'
                            numberClassName='fs-4'
                            unitsClassName='small'
                            unitSize='large'
                            precision={2}
                            onZero={hasCo2eValue ? undefined : 'dash'}
                            showZeroesToPrecision={true}
                        />
                        <InfoIcon
                            hidden={!activityItem.staticData}
                            tooltip={`This value was entered manually and not calculated from the quantity and ${ProductService.elementTitle().toLowerCase()}`}
                            iconProps={{ color: Utils.infoColor }}
                            className='ms-1'
                            tooltipProps={{ tooltipClassName: 'small' }}
                        />
                    </div>
                </div>
                {Utils.inDebugMode() && (
                    <div className='small text-muted ms-auto'>
                        <div className='row'>
                            <div className='col'>Upstream:</div>
                            <div className='col text-end'>
                                <CO2e
                                    co2e={activityItem.impact?.gwp.total.up}
                                    unitSize='large'
                                    onZero={hasCo2eValue ? undefined : 'dash'}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>Downstream:</div>
                            <div className='col text-end'>
                                <CO2e
                                    co2e={activityItem.impact?.gwp.total.down}
                                    unitSize='large'
                                    onZero={hasCo2eValue ? undefined : 'dash'}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }, [
        activityItem,
        staticDataEntry,
        staticSelector,
        co2eValue,
        hasCo2eValue,
        drContext.dataRequest?.uuid,
        perspective.co2e,
        perspective.canEdit,
        isElectricity,
        context.stores.ui,
    ])

    const notes = useMemo(() => {
        if (disabled) {
            if (!activityItem.name) return null
            return (
                <div className='small bg-light p-2 rounded-2'>
                    <div className='text-muted small'>Notes</div>
                    {activityItem.name}
                </div>
            )
        }
        return (
            <div>
                <InputField
                    placeholder='Add notes'
                    className='variable-form-control bg-light rounded-2 w-100'
                    disabled={disabled}
                    multiLine={true}
                    rows={3}
                    defaultValue={activityItem.name}
                    onChangedDebounced={(name) => pSave({ name })}
                />
            </div>
        )
    }, [activityItem, disabled, pSave])

    const files = useMemo(() => {
        if (!activityItem.uuid || isElectricity || disabled) return null
        return (
            <FileManager
                nodeId={activityItem.uuid}
                nodeType={ND.ActivityItem}
                labelClassName='btn btn-sm btn-light'
                disabled={disabled}
            />
        )
    }, [activityItem?.uuid, isElectricity, disabled])

    const classification = useMemo(
        () => (
            <div className='container-fluid d-flex flex-column gap-3'>
                <div className='row align-items-center'>
                    <div className='col col-lg-3 small'>Org</div>
                    <div className='col'>
                        <OrgSelector
                            placement='left-start'
                            disabled={!activityItem.dataRequest && !activityService.isEditable(activityItem)}
                            org={activityItem.org}
                            onSelect={(org) => pSave({ org })}
                        />
                    </div>
                </div>
                <div className='row align-items-center'>
                    <div className='col col-lg-3 small'>Scope</div>
                    <div className='col'>
                        <ScopeSelector
                            placement='left-start'
                            disabled={!activityItem.dataRequest && !activityService.isEditable(activityItem)}
                            scope={activityItem.scope}
                            onSelect={(scope) => pSave({ scope })}
                        />
                    </div>
                </div>
                {FlagService.enabledFlags.has(FlagType.UseBatches) && (
                    <div className='row align-items-center'>
                        <div className='col col-lg-3 small'>Batch</div>
                        <div className='col'>
                            <BatchSelector
                                placement='left-start'
                                disabled={!activityItem.dataRequest && !activityService.isEditable(activityItem)}
                                batch={activityItem.batch || undefined}
                                showGoToBatch={true}
                                showNoValueOption={true}
                                onSelect={(batch) => pSave({ batch })}
                            />
                        </div>
                    </div>
                )}
                {activityItem.dataRequest?.uuid && (
                    <div className='row align-items-center small'>
                        <div className='col col-lg-3'>
                            {DataRequestService.webTitle(false, activityItem.dataRequest)}
                        </div>
                        <div className='col'>
                            <Tooltip
                                trigger='hover'
                                tooltipContent={
                                    <>
                                        This {ActivityService.webTitle()} is part of a{' '}
                                        {DataRequestService.webTitle(false, activityItem.dataRequest)}. Status changes
                                        (submit, approve, etc.) must be done in the{' '}
                                        {DataRequestService.webTitle(false, activityItem.dataRequest)}
                                    </>
                                }
                                tooltipStyle={{ maxWidth: '24rem' }}
                                className='ps-2'
                            >
                                <Link
                                    className='underline-on-hover'
                                    to={DataRequestService.getDataRequestUrl(activityItem.dataRequest)}
                                >
                                    {activityItem.dataRequest?.name}
                                </Link>
                            </Tooltip>
                        </div>
                    </div>
                )}
            </div>
        ),
        [activityItem, pSave],
    )

    const approval = useMemo(() => {
        if (props.hideApprovalActions) return null
        return (
            <div className='fs-base text-center'>
                <ActivityStateManager
                    activityItem={activityItem}
                    onChange={(activityState) => {
                        activityContext.dispatch({
                            type: ActivityActionType.Update,
                            payload: { ...activityItem, state: activityState },
                        })
                        activityContext.dispatch({ type: ActivityActionType.UpdateList })
                    }}
                />
            </div>
        )
    }, [activityItem, props.hideApprovalActions])

    const footer = useMemo(
        () => (
            <div className='fs-base text-center'>
                <div className='d-flex align-items-center justify-content-between'>
                    <Footnotes node={activityItem} saving={saving} />
                    <div className='small'>
                        <Delete
                            hidden={!activityItem.uuid}
                            disabled={disabled && activityItem.state !== ActivityState.REJECTED}
                            disabledTooltip={
                                <span className='d-flex align-items-center gap-1'>
                                    <Warning color={Utils.warningColor} /> Pending and Approved{' '}
                                    {ActivityService.webTitle(true).toLowerCase()} cannot be deleted.
                                </span>
                            }
                            className='text-muted'
                            deleteFn={() =>
                                activityService
                                    .deleteActivity(activityItem.uuid!)
                                    .then(() => {
                                        activityContext.dispatch({
                                            type: ActivityActionType.Remove,
                                            payload: activityItem,
                                        })
                                        Utils.deletedToast('Activity deleted')
                                        analyticsService.track('Delete Activity', { uuid: activityItem?.uuid })
                                    })
                                    .catch(Utils.errorToast)
                            }
                        />
                    </div>
                </div>
            </div>
        ),
        [activityItem, saving, disabled, context.stores.ui],
    )

    return (
        <div className='d-flex flex-column justify-content-between gap-2 flex-grow-1 h-100'>
            <div
                className={[
                    'flex-grow-1 d-flex flex-column pb-3',
                    isElectricity ? 'gap-2' : 'gap-3',
                    'overflow-y-auto',
                ].join(' ')}
            >
                <ActivityOnboarding />
                <div>{typeSelector}</div>
                {inputItem}
                {/*{co2eIntensity}*/}
                {dateRange}
                {quantity}
                {electricityBody}
                {co2eData}
                {customerSelector}
                {notes}
                {files}
                {classification}
            </div>
            {approval}
            {footer}
        </div>
    )
}
