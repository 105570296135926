import InputField from '../Input/InputField'
import Utils from '../../services/utils'
import { Product } from '../../services/product'
import { StandardAttributes } from '../../types'
import { ReactNode, useContext, useMemo } from 'react'
import { ApplicationContext } from '../../context'
import { VariableServicesContext } from '../../services'
import { useProduct } from '../../hooks/useProduct'

export const StaticValues = (
    props: StandardAttributes & {
        product?: Product
        upstreamLabel?: ReactNode
        downstreamLabel?: ReactNode
        showDownstream?: boolean
        onChange: (properties: Partial<Product>) => void
    },
) => {
    const context = useContext(ApplicationContext)
    const { companyService } = useContext(VariableServicesContext)
    const product = useProduct({ product: props.product })
    const isSynced = useMemo(
        () => product?.syncId !== undefined && !companyService.isMyCompany(product?.productOf),
        [product?.syncId, context.stores.company?.uuid],
    )

    if (!product) return null

    return (
        <>
            <div className='small' style={{ gridArea: 'auto / key' }}>
                {props.upstreamLabel || 'Upstream'}
            </div>
            <div
                className='variable-input-group border flex-nowrap bg-light rounded-1'
                style={{ gridArea: 'auto / value' }}
            >
                <InputField
                    disabled={props.disabled || isSynced}
                    placeholder='Documented upstream CO2e'
                    defaultValue={product.staticUpstreamCo2e || product.upstreamCo2e || product.co2e || 0}
                    isNumber={true}
                    numberPrecision={Utils.MAX_DECIMAL_PRECISION}
                    className='variable-form-control bg-white text-end w-100'
                    onChanged={(staticUpstreamCo2e) => {
                        props.onChange({ staticUpstreamCo2e })
                        if (
                            !product.pCfIncludingBiogenic ||
                            product.pCfIncludingBiogenic === parseFloat(product.upstreamCo2e || '0')
                        ) {
                            const inputField = document.getElementById(
                                `pact-input-pCfIncludingBiogenic`,
                            ) as HTMLInputElement
                            if (inputField && inputField.value) {
                                inputField.value =
                                    product.staticUpstreamCo2e || product.upstreamCo2e || product.co2e || ''
                            }
                        }
                    }}
                />
                <span className='variable-input-group-text'>kg{Utils.co2e}</span>
            </div>

            <div hidden={props.showDownstream === false} className='small' style={{ gridArea: 'auto / key' }}>
                {props.downstreamLabel || 'Downstream'}
            </div>
            <div
                hidden={props.showDownstream === false}
                className='variable-input-group border flex-nowrap bg-light rounded-1'
                style={{ gridArea: 'auto / value' }}
            >
                <InputField
                    disabled={props.disabled || isSynced}
                    placeholder='Documented downstream CO2e'
                    defaultValue={product.staticDownstreamCo2e || product.downstreamCo2e || 0}
                    isNumber={true}
                    numberPrecision={Utils.MAX_DECIMAL_PRECISION}
                    className='variable-form-control bg-white text-end w-100'
                    onChanged={(staticDownstreamCo2e) => props.onChange({ staticDownstreamCo2e })}
                />
                <span className='variable-input-group-text'>kg{Utils.co2e}</span>
            </div>
        </>
    )
}
