import Utils from '../../services/utils'
import { FileManager } from '../Input/FileUploader'
import ProductService, {
    dataCompletenessOptions,
    dataReliabilityOptions,
    geographicalRepresentativenessOptions,
    pathfinderAttributes,
    PathfinderDataQualityRatingValue,
    Product,
    technologicalRepresentativenessOptions,
} from '../../services/product'
import { Selector } from '../Input/Selector'
import { useCallback, useContext, useMemo } from 'react'
import { VariableServicesContext } from '../../services'
import { CloudCheck } from '@phosphor-icons/react'
import { ApplicationContext } from '../../context'
import { PactRequired } from '../Icons/PactRequired'
import { YearSelector } from '../Input/YearSelector'
import { useProduct } from '../../hooks/useProduct'
import { ND } from '../../types'
import { StaticValues } from './StaticValues'
import { PactField } from './PactField'

export const ProductTypeStatic = (props: {
    product: Product
    disabled?: boolean
    thinView?: boolean
    onChange: (properties: Partial<Product>) => void
}) => {
    const context = useContext(ApplicationContext)
    const { companyService } = useContext(VariableServicesContext)

    const product = useProduct({ product: props.product })

    const isSynced = useMemo(
        () => product?.syncId !== undefined && !companyService.isMyCompany(product?.productOf),
        [product?.syncId, context.stores.company?.uuid],
    )

    const mainColumnClass = useCallback(
        (col: 1 | 2) => {
            if (props.thinView) {
                return 'col col-lg-6'
            }
            if (col === 1) return 'col-12 col-lg-6 col-xl-4'
            if (col === 2) return 'col-12 col-lg-6 col-xl-8'
            return 'col'
        },
        [props.thinView],
    )

    const rightColumnClass = useCallback(() => {
        if (props.thinView) {
            return 'col'
        }
        return 'col-12 col-xl-6'
    }, [props.thinView])

    if (!product) return null

    return (
        <div className='container-fluid overflow-auto'>
            {isSynced && (
                <div className='bg-warning bg-opacity-10 p-2 rounded-2 mt-2 mb-3 small shadow-sm'>
                    <CloudCheck /> The fields below are not editable because this {ProductService.elementTitle()} is
                    synced with an external data source.
                </div>
            )}
            <div className='row'>
                <div className={mainColumnClass(1)}>
                    <h6 className='m-0 py-2'>Emissions</h6>
                    <div
                        className='d-grid align-items-center gap-3'
                        style={{ gridTemplate: 'auto / [key] 1fr [value] 1fr' }}
                    >
                        <StaticValues {...props} />
                        <hr className='m-0' style={{ gridColumn: '1 / span 2' }} />
                        {pathfinderAttributes?.map((pactAttr) => (
                            <PactField
                                key={`pact-attr-${pactAttr.key}`}
                                {...props}
                                disabled={isSynced}
                                pactAttr={pactAttr}
                            />
                        ))}
                    </div>
                </div>
                <div className={mainColumnClass(2)}>
                    <div className='row gap-4 gap-xl-0'>
                        <div className={[rightColumnClass(), 'order-xl-2'].join(' ')}>
                            <h6 className='m-2'>Documentation</h6>
                            <FileManager
                                nodeId={product.uuid}
                                nodeType={ND.Product}
                                disabled={!product?.uuid || props.disabled || isSynced}
                                label='Upload documents'
                                documentListProps={{
                                    className: 'my-2 mb-1 d-flex flex-wrap align-items-center gap-1',
                                    documentClassName: 'mb-1',
                                }}
                                labelProps={{ className: 'fill-parent visually-hidden' }}
                                labelClassName='btn btn-sm btn-light'
                            />
                        </div>
                        <div className={[rightColumnClass(), 'order-xl-1'].join(' ')}>
                            <div className='d-table w-100'>
                                <div className='d-table-row'>
                                    <div className='d-table-cell align-middle'>
                                        <h6 className='m-0'>Data quality indicators</h6>
                                    </div>
                                    <div className='d-table-cell align-middle' />
                                </div>
                                <div className='d-table-row'>
                                    <div className='d-table-cell small'>
                                        Year documented
                                        <PactRequired required={true} />
                                    </div>
                                    <div className='d-table-cell'>
                                        <YearSelector
                                            option={product.qualitySummary?.documentationYear}
                                            className='variable-select-arrow'
                                            itemExtraClassName='font-monospace'
                                            disabled={props.disabled || isSynced}
                                            onChange={(documentationYear) => {
                                                const thisYear = Utils.dayjs().year()
                                                let temporalRepresentativeness: PathfinderDataQualityRatingValue = 3
                                                if (documentationYear === thisYear) {
                                                    temporalRepresentativeness = 1
                                                } else if (thisYear - documentationYear <= 5) {
                                                    temporalRepresentativeness = 2
                                                }
                                                props.onChange({
                                                    qualitySummary: {
                                                        ...product.qualitySummary,
                                                        documentationYear,
                                                        temporalRepresentativeness,
                                                    },
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='d-table-row'>
                                    <div className='d-table-cell small'>
                                        Technological representativeness
                                        <PactRequired required={true} />
                                    </div>
                                    <div className='d-table-cell'>
                                        <Selector
                                            disabled={props.disabled || isSynced}
                                            hideTextFilter={true}
                                            options={technologicalRepresentativenessOptions}
                                            option={product.qualitySummary?.technologicalRepresentativeness}
                                            className='variable-select-arrow'
                                            tooltipStyle={{ maxWidth: '320px' }}
                                            onSelect={(r) => {
                                                props.onChange({
                                                    qualitySummary: {
                                                        ...product.qualitySummary,
                                                        technologicalRepresentativeness: r.value,
                                                    },
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='d-table-row'>
                                    <div className='d-table-cell small'>
                                        Geographical representativeness
                                        <PactRequired required={true} />
                                    </div>
                                    <div className='d-table-cell'>
                                        <Selector
                                            disabled={props.disabled || isSynced}
                                            hideTextFilter={true}
                                            options={geographicalRepresentativenessOptions}
                                            option={product.qualitySummary?.geographicalRepresentativeness}
                                            className='variable-select-arrow'
                                            tooltipStyle={{ maxWidth: '320px' }}
                                            onSelect={(r) => {
                                                props.onChange({
                                                    qualitySummary: {
                                                        ...product.qualitySummary,
                                                        geographicalRepresentativeness: r.value,
                                                    },
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='d-table-row'>
                                    <div className='d-table-cell small'>
                                        Completeness
                                        <PactRequired required={true} />
                                    </div>
                                    <div className='d-table-cell'>
                                        <Selector
                                            disabled={props.disabled || isSynced}
                                            hideTextFilter={true}
                                            options={dataCompletenessOptions}
                                            option={product.qualitySummary?.dataCompleteness}
                                            className='variable-select-arrow'
                                            tooltipStyle={{ maxWidth: '320px' }}
                                            onSelect={(r) => {
                                                props.onChange({
                                                    qualitySummary: {
                                                        ...product.qualitySummary,
                                                        dataCompleteness: r.value,
                                                    },
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='d-table-row'>
                                    <div className='d-table-cell small'>
                                        Reliability
                                        <PactRequired required={true} />
                                    </div>
                                    <div className='d-table-cell'>
                                        <Selector
                                            disabled={props.disabled || isSynced}
                                            hideTextFilter={true}
                                            options={dataReliabilityOptions}
                                            option={product.qualitySummary?.dataReliability}
                                            className='variable-select-arrow'
                                            tooltipStyle={{ maxWidth: '320px' }}
                                            onSelect={(r) => {
                                                props.onChange({
                                                    qualitySummary: {
                                                        ...product.qualitySummary,
                                                        dataReliability: r.value,
                                                    },
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
