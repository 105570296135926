import InputService, { Input } from '../../services/input'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import ProductService, { Product } from '../../services/product'
import PartService, { PartType } from '../../services/part'
import Tooltip from '../Tooltip'
import { Link } from 'react-router-dom'
import CO2e from '../CO2e'
import Utils from '../../services/utils'
import Spinner from '../Spinner'
import InputField from '../Input/InputField'
import { UnitHeader } from '../UnitHeader'
import { MiniPassport } from '../MiniPassport'
import Button from '../Input/Button'
import { ConnectionStatus } from '../Icons/ConnectionStatus'
import Delete from '../Delete'
import { CircularIcon } from '../Icons/CircularIcon'
import { Nut, SidebarSimple } from '@phosphor-icons/react'
import { ApplicationContext } from '../../context'
import { VariableServicesContext } from '../../services'
import { PrettyNumber } from '../PrettyNumber'
import { usePartForElementCreator } from '../../hooks/usePartForElementCreator'
import { PartIcon } from '../Icons/PartIcon'
import UnitService from '../../services/unit'
import { InventoryIcon } from '../Icons/InventoryIcon'
import { useInput } from '../../hooks/useInput'
import { usePart } from '../../hooks/usePart'
import { useProduct } from '../../hooks/useProduct'
import { InventoryService } from '../../services/inventory'

export const PartOptions = (props: {
    input?: Input
    className?: string
    disabled?: boolean
    onChange: (properties?: Partial<Input>) => void
    onClear: () => void
}) => {
    const context = useContext(ApplicationContext)
    const { partService, categoryModelService } = useContext(VariableServicesContext)
    const [sourceProducts, setSourceProducts] = useState<Product[]>()
    const [showingFootprintOptions, setShowingFootprintOptions] = useState<boolean>(false)
    const [fetchingSourceProducts, setFetchingSourceProducts] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)

    const input = useInput({ input: props.input })
    const part = usePart({ part: input?.part })
    const sourceProduct = useProduct({ product: input?.sourceProduct })

    const createPartForElement = usePartForElementCreator({
        onCreate: () => {
            const _input = InputService.byId.get(context.stores.input?.currentId || '')
            props.onChange({ part: _input?.part, sourceProduct: _input?.sourceProduct })
            setOpen(false)
        },
    })

    const circularIconWithNumber = useMemo(
        () => <CircularIconWithNumber circular={input?.circular} />,
        [input?.circular],
    )

    useEffect(() => {
        if (context.stores.products.preview?.uuid === sourceProduct?.uuid) {
            setShowingFootprintOptions(false)
        }
    }, [context.stores.products.preview?.uuid, sourceProduct?.uuid])

    useEffect(() => {
        if (open && part?.uuid) {
            setFetchingSourceProducts(true)
            partService
                .getSourceProducts(part)
                .then(setSourceProducts)
                .finally(() => setFetchingSourceProducts(false))
        }
    }, [open])

    const reuseCycles = useMemo(
        () => (
            <fieldset className='d-flex align-items-center justify-content-between small'>
                <label htmlFor={`circ-${input?.uuid}`} className='text-nowrap'>
                    <CircularIcon className='me-1' />
                    Use cycles:
                </label>
                <InputField
                    id={`circ-${input?.uuid}`}
                    placeholder='(default: 1)'
                    type='number'
                    min={1}
                    step={1}
                    className='variable-form-control active ms-1 text-end'
                    style={{ maxWidth: '7rem' }}
                    defaultValue={input?.circular}
                    onChanged={(newValue) => props.onChange({ circular: parseInt(newValue, 10) })}
                />
            </fieldset>
        ),
        [input?.uuid, input?.circular],
    )

    const openElementPanel = useCallback(() => partService.openPartEditor(part, input?.uuid), [part, input?.uuid])

    const openElementDetailsBtn = (
        <Link
            to={PartService.getPartUrl(part)}
            className='underline-on-hover py-2 small'
            onClick={(e) => {
                if (props.disabled) {
                    return
                }
                if (!Utils.isModifierKey(e)) {
                    e.preventDefault()
                }
                openElementPanel()
                setOpen(false)
            }}
        >
            {PartService.webTitle()} details
            <SidebarSimple style={{ transform: 'rotate(180deg)' }} className='ms-1 nt--1' />
        </Link>
    )

    const clearButton = (
        <div className='border-top pt-2'>
            <Delete
                tooltipProps={{ placement: 'top-start', tooltipClassName: 'text-start' }}
                className='btn btn-sm btn-plain underline-on-hover text-start'
                deleteFn={async () => props.onClear()}
            >
                Remove
            </Delete>
        </div>
    )

    if (!part?.uuid && sourceProduct?.uuid) {
        return (
            <Tooltip
                interactive={true}
                ariaLabel='Element Detail'
                disabled={props.disabled}
                placement='bottom-start'
                positioning='fixed'
                visible={showingFootprintOptions}
                onVisibleChange={setShowingFootprintOptions}
                tooltipClassName='text-base-font'
                tooltipContent={
                    <>
                        <MiniPassport product={sourceProduct} />
                        <div className='py-2'>
                            <Button
                                hidden={!input?.uuid}
                                onClick={() => {
                                    input && createPartForElement(input)
                                    setShowingFootprintOptions(false)
                                }}
                                className='btn btn-sm btn-plain text-primary text-start underline-on-hover shadow-none'
                            >
                                + Add alternatives
                            </Button>
                        </div>
                        {reuseCycles}
                        {clearButton}
                    </>
                }
                tooltipStyle={{ minWidth: '300px' }}
                className='input-element'
            >
                <div className='d-flex align-items-center gap-1'>
                    <InventoryIcon inv={InventoryService.productToInventory(sourceProduct)} className='flex-shrink-0' />
                    <span className='d-block text-start text-overflow-ellipsis'>{sourceProduct?.name}</span>
                    {circularIconWithNumber}
                </div>
            </Tooltip>
        )
    }

    if (!part?.uuid) return null

    if (part?.type === PartType.CONFIG) {
        return (
            <Button
                onClick={async () => {
                    if (!part?.uuid) return
                    const fullPart = await partService.getPart(part.uuid)
                    if (!fullPart.emissionFactor?.footprint?.categoryModel) return
                    categoryModelService.setCategoryModelConfig(fullPart.emissionFactor?.footprint?.categoryModel, part)
                }}
                className={[
                    'input-element text-start',
                    part.emissionFactor?.footprint?.categoryModel?.uuid &&
                    context.stores.categoryModel.model?.uuid === part.emissionFactor?.footprint?.categoryModel?.uuid
                        ? 'active'
                        : '',
                ].join(' ')}
            >
                <Nut /> {part?.name}
            </Button>
        )
    }

    const sourceProductList = (
        <div className='d-table small'>
            <div className='d-table-row table-header'>
                <span className='d-table-cell fw-normal'>Select Source</span>
                <span className='d-table-cell text-end'>
                    <UnitHeader unitSize='small' />
                </span>
            </div>
            {fetchingSourceProducts && (
                <div className='p-2'>
                    <span className='spinner-border spinner-border-sm opacity-50' />
                </div>
            )}
            {sourceProducts?.sort(Utils.sortByName)?.map((sp) => (
                <Link
                    to={ProductService.getProductUrl(sp)}
                    onClick={(e) => {
                        if (!Utils.isModifierKey(e)) {
                            e.preventDefault()
                            if (sourceProduct?.uuid !== sp.uuid) {
                                props.onChange({
                                    sourceProduct: sp,
                                    unit: UnitService.getNewUnit(input?.unit, sp),
                                })
                            }
                        }
                    }}
                    className={[
                        'd-table-row dropdown-item px-1',
                        sourceProduct?.uuid === sp.uuid
                            ? 'border-start border-primary border-2 bg-primary bg-opacity-5'
                            : '',
                    ].join(' ')}
                    key={`sp-${input?.uuid}-${sp.uuid}`}
                >
                    <span className='d-table-cell p-1'>
                        {sp.productOf?.name ? (
                            <span className='small text-muted'>{sp.productOf.name}</span>
                        ) : (
                            <span className='small text-very-muted'>(Unspecified Supplier)</span>
                        )}
                        <span className='d-block'>
                            <ConnectionStatus company={sp?.productOf} className='me-1' />
                            {sp.name || '(Unnamed Product)'}
                        </span>
                    </span>
                    <CO2e
                        className='d-table-cell p-1 text-end'
                        product={sp}
                        co2e={sp.upstreamCo2e || sp.co2e}
                        unitSize='small'
                        showUnit={false}
                        unitsClassName='d-block small text-muted'
                        functionalUnit={sp?.unit?.code}
                    />
                </Link>
            ))}
        </div>
    )

    const switchBody = (
        <>
            {sourceProductList}
            {openElementDetailsBtn}
            <div className='border-top pt-2'>{reuseCycles}</div>
            {clearButton}
        </>
    )

    const mixBody = (
        <>
            <Button
                className='btn btn-plain bg-light-hover'
                disabled={props.disabled}
                onClick={() => {
                    openElementPanel()
                    setOpen(false)
                }}
            >
                <div className='d-flex align-items-center justify-content-between gap-2 small'>
                    Average of {part?.sourceProductCount} {Utils.pluralize('soure', part?.sourceProductCount)}
                    <CO2e
                        className='d-table-cell p-1 text-end'
                        co2e={part.liveCo2e}
                        unitSize='small'
                        functionalUnit={part?.unit?.code}
                        unitsClassName='d-block small text-muted'
                        functionalUnitClassName='ms-1'
                    />
                </div>
            </Button>
            {openElementDetailsBtn}
            <div className='border-top pt-2'>{reuseCycles}</div>
            {clearButton}
        </>
    )

    const factorBody = (
        <>
            <div className='bg-info bg-opacity-5 small p-2'>
                This {PartService.webTitle()} is using an average emission factor.
                <br />
                You can add sources in the {PartService.webTitle()} details.
            </div>
            {openElementDetailsBtn}
            {reuseCycles}
            {clearButton}
        </>
    )

    let tooltipClassName = 'input-element'
    let tooltipContent = <Spinner size='sm' />
    switch (part.type) {
        case PartType.MIX:
            tooltipContent = mixBody
            break
        case PartType.SWITCH:
            tooltipContent = switchBody
            break
        case PartType.UNKNOWN:
            tooltipContent = factorBody
            break
    }
    if (!part?.sourceProducts?.length) {
        tooltipContent = factorBody
    }

    let buttonBody = (
        <>
            <span className='d-flex align-items-center gap-1 text-overflow-ellipsis'>
                <PartIcon part={part} className='flex-shrink-0' />
                <ConnectionStatus company={input?.sourceProduct?.productOf} />
                <span className='text-overflow-ellipsis'>
                    {sourceProduct && (sourceProduct?.name || sourceProduct?.productOf?.name || '')}
                    {!sourceProduct &&
                        (part?.name || <span className='text-muted'>(Unnamed {PartService.webTitle()})</span>)}
                </span>
                {circularIconWithNumber}
            </span>
        </>
    )

    if (part.type === PartType.MIX) {
        tooltipClassName = 'input-element w-auto'
        buttonBody = (
            <span className='d-flex align-items-center gap-1 text-overflow-ellipsis'>
                <PartIcon part={part} className='flex-shrink-0 nt--1' />
                <span className='text-overflow-ellipsis'>
                    {PartService.getPartString(part)}: {part.name || `(Unnamed ${PartService.webTitle()})`}
                </span>
                {circularIconWithNumber}
            </span>
        )
    }

    if (
        [PartType.MIX, PartType.SWITCH].includes(part?.type as PartType) &&
        context.stores.parts.selectedId === part.uuid
    ) {
        tooltipClassName += ' active'
    }

    return (
        <Tooltip
            interactive={true}
            ariaLabel='Element Detail'
            type='widget'
            placement='bottom-start'
            positioning='fixed'
            disabled={props.disabled}
            closeButton={true}
            visible={open}
            onVisibleChange={setOpen}
            tooltipContent={<div className='d-flex flex-column gap-2'>{tooltipContent}</div>}
            onDoubleClick={() => openElementPanel()}
            className={tooltipClassName}
            tooltipClassName='text-base-font p-2'
            tooltipStyle={{ minWidth: '300px' }}
        >
            {buttonBody}
        </Tooltip>
    )
}

export const CircularIconWithNumber = (props: { circular?: number }) => {
    return (
        <span
            hidden={!props.circular || isNaN(props.circular) || props.circular <= 1}
            className='mb-0 ms-1 small text-alt-font'
        >
            <CircularIcon size={Utils.verySmallIconSize} style={{ marginRight: '1px' }} className='nt--1' />
            <PrettyNumber num={props.circular} precision={0} />x
        </span>
    )
}
