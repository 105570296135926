import {
    CSSProperties,
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { OrderDir, QueryOptions } from '../types'
import Utils from '../services/utils'
import InputField from '../components/Input/InputField'
import Button from '../components/Input/Button'
import { MagnifyingGlass, X } from '@phosphor-icons/react'

export interface QueryParam {
    [name: string]: string | undefined | null
}

export interface Pagination {
    paginator: ReactNode
    searchBox: ReactNode
    searchTerm?: string
    setSearchTerm: Dispatch<SetStateAction<string | undefined>>
    totalCount: number
    queryOptions?: QueryOptions
    queryString?: string
    setTotalCount: (num: number) => void
    pageSize: number
    currentPage: number
    setCurrentPage: (num: number) => void
    setQueryParams: (params: QueryParam, resetPage?: boolean, replace?: boolean, resetOtherParams?: boolean) => void
}

export interface PaginationConfig {
    limit: number
    orderBy?: string
    orderDir?: OrderDir
    searchBoxPlaceholder?: string
    searchBoxClassName?: string
    searchBoxStyle?: CSSProperties
    searchOnKeyPress?: boolean
}

const getQueryKeys = (): { [key: string]: string } => {
    return {
        pageKey: 'page',
        limitKey: 'limit',
        orderByKey: 'orderBy',
        orderDirKey: 'orderDir',
        offsetKey: 'offset',
        filterByKey: 'filterBy',
        depthKey: 'depth',
        slimKey: 'slim',
        idKey: 'id',
        directionKey: 'direction',
        queryKey: 'query',
        slugKey: 'slug',
        whenKey: 'when',
        startDateKey: 'startDate',
        endDateKey: 'endDate',
        periodKey: 'period',
        yearKey: 'year',
        sdKey: 'sd',
        edKey: 'ed',
        archivedKey: 'archived',
        activityStates: 'activityStates',
        queryElementType: 'elementType',
        querySupplierId: 'supplier',
        queryCustomerId: 'customer',
        querySourceId: 'source',
        queryPartId: 'part',
        queryProductId: 'product',
        queryInventoryId: 'inventory',
        queryOrgId: 'org',
        queryTaxonomyId: 'taxonomy',
        queryCategoryPath: 'category',
        queryScopeId: 'scope',
        queryTransportTypeId: 'transportType',
        queryUnitTypeId: 'unitType',
        queryDataRequestId: 'data-request',
        queryGeoLocationId: 'geoLocation',
        queryUserId: 'user',
        queryCreatedById: 'createdBy',
    }
}

function getQueryOptionObject(qs: URLSearchParams, paginationConfig?: PaginationConfig): QueryOptions {
    let queryOpts: QueryOptions = {
        ...Utils.filterSettings,
        ...paginationConfig,
    }
    const qo = getQueryKeys()
    Object.values(qo).forEach((key) => {
        if (qs.get(key)) {
            let keyValue: string | number | null = qs.get(key)
            if (key === 'startDate' || key === 'endDate' || key === 'year') {
                keyValue = parseInt(keyValue || '0')
            }
            queryOpts[key] = keyValue
        }
    })
    if (queryOpts[qo.limitKey] === '100') {
        delete queryOpts[qo.limitKey]
    }
    if (queryOpts[qo.whenKey] === undefined) {
        queryOpts[qo.whenKey] = 'ytd'
    }
    // console.log(queryOpts)
    if (queryOpts.when === 'range' || queryOpts.when === 'year') {
        Utils.filterSettings = {
            when: queryOpts.when,
            year: queryOpts.year,
            startDate: queryOpts.startDate,
            endDate: queryOpts.endDate,
            sd: queryOpts.sd,
            ed: queryOpts.ed,
        }
    } else {
        Utils.filterSettings = {
            ...Utils.filterSettings,
            when: queryOpts.when,
        }
        delete queryOpts.year
        delete queryOpts.startDate
        delete queryOpts.endDate
        delete queryOpts.sd
        delete queryOpts.ed
    }
    // console.log(Utils.filterSettings.startDate === queryOpts.startDate, Utils.filterSettings, queryOpts)
    return queryOpts
}

function getQueryOptionString(opts: QueryOptions): URLSearchParams {
    const qs = new URLSearchParams()
    if (!opts) {
        return qs
    }
    const qo = getQueryKeys()
    Object.values(qo).forEach((key) => {
        if (opts[key]) {
            qs.set(key, opts[key])
        }
    })
    return qs
}

const DEFAULT_LIMIT = 100

function usePaginate(paginationConfig?: Partial<PaginationConfig>): Pagination {
    const location = useLocation()
    const navigate = useNavigate()
    const [params] = useSearchParams()
    const [queryString, setQueryString] = useState<string>()
    const [queryOptions, setQueryOptions] = useState<QueryOptions>()
    const [totalCount, setTotalCount] = useState<number>(0)
    const [searchTerm, setSearchTerm] = useState<string>()
    const searchRef = useRef<any>()

    const config: PaginationConfig = useMemo(() => ({ limit: DEFAULT_LIMIT, ...paginationConfig }), [paginationConfig])

    useEffect(() => {
        params.set('limit', config.limit.toString())

        const qo = getQueryOptionObject(params, config)
        if (qo !== queryOptions) setQueryOptions(qo)

        const qs = getQueryOptionString(qo).toString()
        if (qs !== queryString) setQueryString(qs)
    }, [params])

    useEffect(() => {
        if (searchRef.current && queryOptions?.query) searchRef.current.value = queryOptions?.query
    }, [queryOptions?.query])

    const pageCount = useMemo(() => {
        if (!totalCount) return 0
        return Math.ceil(totalCount / config.limit)
    }, [totalCount, config?.limit])

    const currentPage = useMemo(() => parseInt(queryOptions?.page?.toString() || '1', 10), [queryOptions?.page])

    const setQueryParams = useCallback(
        (
            params: QueryParam,
            resetPage: boolean = false,
            replace: boolean = true,
            resetOtherParams: boolean = false,
        ): void => {
            const qs = new URLSearchParams(resetOtherParams ? '' : document.location.search)
            if (resetPage) qs.delete('page')
            for (const param in params) {
                const paramValue = params[param]
                if (paramValue !== undefined && paramValue !== null) {
                    qs.set(param, paramValue)
                } else {
                    qs.delete(param)
                }
            }
            navigate({ search: qs.toString() }, { replace: replace })
        },
        [location.pathname],
    )

    const setCurrentPage = useCallback(
        (num: number) => setQueryParams({ page: num.toString() }),
        [setQueryParams, location.pathname],
    )

    const paginator = useMemo(() => {
        if (pageCount < 2) {
            return null
        }
        const middlePage = currentPage > 2 ? currentPage - 2 : 1
        let middles = pageCount > 5 ? 5 : pageCount
        const remaining = pageCount - currentPage
        if (pageCount > 3 && remaining < 2) {
            middles = 3 + (remaining > -1 ? remaining : 0)
        }
        return (
            <div className='input-group input-group-sm'>
                <Button
                    disabled={currentPage === 1}
                    className='btn btn-sm btn-light border'
                    onClick={() => {
                        const newPage = currentPage - 1
                        setCurrentPage(newPage || 1)
                    }}
                >
                    Previous
                </Button>
                {pageCount > 1 &&
                    new Array(middles).fill(middlePage).map((_page, idx) => {
                        let pg = middlePage + idx
                        return (
                            <Button
                                key={`page-${idx}`}
                                className={['btn btn-sm', pg === currentPage ? 'btn-primary' : 'btn-light border'].join(
                                    ' ',
                                )}
                                onClick={() => setCurrentPage(pg)}
                            >
                                {pg}
                            </Button>
                        )
                    })}
                <Button
                    disabled={currentPage === pageCount}
                    className='btn btn-sm btn-light border'
                    onClick={() => {
                        let newPage = currentPage + 1
                        if (newPage > pageCount) {
                            newPage = pageCount
                        }
                        setCurrentPage(newPage)
                    }}
                >
                    Next
                </Button>
            </div>
        )
    }, [currentPage, pageCount, setCurrentPage])

    const searchBox = useMemo(
        () => (
            <span className='d-block position-relative'>
                <span className='position-absolute z-index-sticky' style={{ top: '.25rem', left: '.4rem' }}>
                    <MagnifyingGlass size={Utils.verySmallIconSize} color={Utils.bodyColor} />
                </span>
                <InputField
                    passedRef={searchRef}
                    ariaLabel='Search'
                    placeholder={paginationConfig?.searchBoxPlaceholder || 'Search'}
                    className={
                        paginationConfig?.searchBoxClassName || 'variable-form-control bg-white w-100 border px-4'
                    }
                    style={paginationConfig?.searchBoxStyle}
                    defaultValue={queryOptions?.query || ''}
                    onChange={(_searchTerm) => {
                        setSearchTerm(_searchTerm)
                        if (config.searchOnKeyPress) {
                            setQueryParams({ query: _searchTerm || undefined }, true)
                        }
                    }}
                    onEnterOrBlur={(hasChanged) => {
                        if (!config.searchOnKeyPress && hasChanged) {
                            setQueryParams({ query: searchTerm || undefined }, true)
                        }
                    }}
                />
                <Button
                    hidden={!queryOptions?.query}
                    className='btn btn-sm btn-plain position-absolute bg-light-hover px-1'
                    style={{ top: '.25rem', right: '.2rem' }}
                    onClick={() => {
                        setSearchTerm(undefined)
                        setQueryParams({ query: undefined }, true)
                        searchRef.current.value = ''
                    }}
                >
                    <X size={Utils.verySmallIconSize} />
                </Button>
            </span>
        ),
        [searchRef, paginationConfig, setQueryParams, queryOptions, searchTerm],
    )

    return {
        pageSize: config.limit,
        paginator,
        setQueryParams,
        searchBox,
        searchTerm,
        setSearchTerm,
        totalCount,
        setTotalCount,
        queryOptions,
        queryString,
        currentPage,
        setCurrentPage,
    }
}

export default usePaginate
