import { Amount, GenericObject, ND, VariableBaseNode, VariableNode } from '../types'
import { Product } from './product'
import { GeoLocation } from './geoLocation'
import { Input } from './input'
import VariableService from './service'
import UnitService from './unit'
import Utils from './utils'
import { ActionMap } from '../context'
import { InventoryActionType, InventoryService } from './inventory'
import { Taxonomy } from './taxonomy'
import { Location } from './location'

export type TransportationMode =
    | 'ferry'
    | 'container-ship'
    | 'tanker'
    | 'inland-waterways-barge'
    | 'inland-waterways-tanker'
    | 'truck'
    | 'train'
    | 'air'
export type TransportationCooling = null | 'cooling' | 'freezing'
export type TankerType = null | 'petroleum' | 'lng' | 'other-liquid' | 'dry-goods'
export type DistanceSource = 'google' | 'searoutes' | 'cartesian' | 'manual'

export interface TransportSegment extends VariableBaseNode {
    order?: number
    origin?: GeoLocation | null
    destination?: GeoLocation | null
    originLocation?: Location | null
    destinationLocation?: Location | null
    name?: string
    mode?: TransportationMode
    cooling?: TransportationCooling
    tanker?: TankerType
    staticDistance?: Amount
    distance?: Amount
    distanceSource?: DistanceSource
    overviewPolyline?: string
    coordinatePath?: string
    customFootprint?: boolean
    footprintSyncId?: string
    footprint?: Partial<Product>
}

export interface TransportDistance extends TransportSegment {
    distanceInMeters?: number
    overviewPolyline?: string
    coordinatePath?: string
}

export interface TransportType extends VariableBaseNode {
    summary?: string
    totalKm?: number
    co2e?: string
    avgCo2e?: string
    segments?: TransportSegment[]
    instances?: TransportInstance[]
    taxonomy?: Taxonomy
    partOfCount?: number
    partOf?: Product[]
    activityCount?: number
    activityCo2e?: string
    dataImportId?: string
}

export interface TransportInstance extends VariableBaseNode {
    transportType?: TransportType
    node?: VariableNode
    nodeType?: ND
    weight?: Amount
    tkm?: number
    co2e?: string
}

export interface ITransportTypeEditor {
    instanceId?: string
    transportTypeId?: string
    node?: VariableNode
    focus?: string
    canDelete?: boolean
    deletedTransportInstance?: TransportInstance
    transportTypesUpdated?: number
    updates?: number
}

export const emptyITransportTypeEditor: ITransportTypeEditor = {
    instanceId: undefined,
    transportTypeId: undefined,
    node: undefined,
    canDelete: undefined,
    deletedTransportInstance: undefined,
    transportTypesUpdated: 0,
    updates: 0,
}

export enum TransportActionType {
    SetTransportTypesUpdated = 'SetTransportTypesUpdated',
    SetTransportTypeId = 'SetTransportTypeId',
    UnsetTransportType = 'UnsetTransportType',
    SetTransportContext = 'SetTransportContext',
    UnsetTransportContext = 'UnsetTransportContext',
    SetTransportInstanceId = 'SetTransportInstanceId',
    UpdateTransportInstance = 'UpdateTransportInstance',
    DeleteTransportInstance = 'DeleteTransportInstance',
}

type TransportActionPayload = {
    [TransportActionType.SetTransportTypesUpdated]: undefined
    [TransportActionType.SetTransportTypeId]: string | undefined
    [TransportActionType.UnsetTransportType]: undefined
    [TransportActionType.SetTransportContext]: ITransportTypeEditor
    [TransportActionType.UnsetTransportContext]: undefined
    [TransportActionType.SetTransportInstanceId]: string | undefined
    [TransportActionType.UpdateTransportInstance]: undefined
    [TransportActionType.DeleteTransportInstance]: TransportInstance
}

export type TransportActions = ActionMap<TransportActionPayload>[keyof ActionMap<TransportActionPayload>]

export const TransportReducer = (state: ITransportTypeEditor, action: TransportActions): ITransportTypeEditor => {
    switch (action.type) {
        case TransportActionType.SetTransportTypesUpdated:
            return { ...state, transportTypesUpdated: (state?.transportTypesUpdated || 0) + 1 }
        case TransportActionType.UnsetTransportType:
            return { ...state, transportTypeId: undefined }
        case TransportActionType.SetTransportTypeId:
            return { ...state, transportTypeId: action.payload }
        case TransportActionType.SetTransportContext:
            return {
                ...state,
                ...action.payload,
                focus: action.payload.focus || undefined,
                updates: 0,
            }
        case TransportActionType.SetTransportInstanceId:
            return { ...state, instanceId: action.payload }
        case TransportActionType.UpdateTransportInstance:
            return { ...state, updates: (state?.updates || 0) + 1 }
        case TransportActionType.UnsetTransportContext:
            return { ...emptyITransportTypeEditor }
        case TransportActionType.DeleteTransportInstance:
            return {
                ...state,
                updates: (state?.updates || 0) + 1,
                deletedTransportInstance: action.payload,
            }
        default:
            return state
    }
}

export default class TransportService extends VariableService {
    private basePath: string = '/transport'
    public static webRoot: string = '/transport'
    public static webTitle = (): string => 'Transport'
    public static defaultTransportName = 'Transport'

    public static transportTypes: TransportType[] = []
    public static transportTypeById: Map<string, TransportType> = new Map<string, TransportType>()
    public static transportInstanceById: Map<string, TransportInstance> = new Map<string, TransportInstance>()
    public static transportNewId: string = 'newTransport'

    public static updateTransportInstanceContext(transportInstances: TransportInstance[]) {
        transportInstances?.forEach((ti) => {
            if (ti.uuid) {
                const existing = TransportService.transportInstanceById.get(ti.uuid)
                TransportService.transportInstanceById.set(ti.uuid, { ...existing, ...ti })
            }
        })
    }

    public updateTransportInstanceContext(transportInstances: TransportInstance[]) {
        TransportService.updateTransportInstanceContext(transportInstances)
        this.context.dispatch({ type: TransportActionType.UpdateTransportInstance })
    }

    public static getTransportTypeUrl(transportType?: TransportType, fullUrl: boolean = false): string {
        let url = `${fullUrl ? document.location.origin : ''}${this.webRoot}`
        if (!transportType) {
            return url
        }
        return `${url}/${transportType.uuid}`
    }

    public static getEmptyTransportType(): TransportType {
        return { segments: [{ mode: undefined }] }
    }

    public static getEmptyTransportInstance(input?: Input, transportType?: TransportType): TransportInstance {
        let _amount: Amount | undefined = undefined
        if (input?.uuid && input.unit?.type === 'weight') {
            _amount = { quantity: input.quantity, unit: input.unit }
        }
        return { transportType, weight: _amount || { quantity: undefined, unit: UnitService.unitByCode['kg'] } }
    }

    public static getTransportTypeName(transportType?: TransportType, fallback?: string): string {
        let name = transportType?.name
        if (!name || name === this.defaultTransportName) {
            if (transportType?.segments?.length) {
                const origin = transportType?.segments[0].origin?.formattedAddress
                const destination =
                    transportType?.segments[transportType?.segments.length - 1].destination?.formattedAddress
                if (origin) {
                    name = origin
                }
                if (destination) {
                    name = name ? `${name} to ${destination}` : destination
                }
            }
        }
        return name || fallback || '(Transport)'
    }

    public static getTransportTypeSummary(transportType?: TransportType): string {
        const hasName = transportType?.name && transportType.name !== this.defaultTransportName
        let summary: Set<string> = new Set<string>()
        const segmentLength = transportType?.segments?.length || 0
        if (hasName || segmentLength > 2) {
            transportType?.segments?.forEach((s) => {
                s.origin?.formattedAddress && summary.add(s.origin.formattedAddress)
                s.destination?.formattedAddress && summary.add(s.destination.formattedAddress)
            })
        }
        return Array.from(summary).join('; ')
    }

    public static getMode = (taxonomy?: Taxonomy | null, fallback?: TransportationMode): TransportationMode => {
        const _txPath = taxonomy?.path
        if (_txPath?.includes('air')) {
            return 'air'
        } else if (_txPath?.includes('sea') || _txPath?.includes('water') || _txPath?.includes('ship')) {
            return 'container-ship'
        } else if (_txPath?.includes('train') || _txPath?.includes('rail')) {
            return 'train'
        } else if (_txPath?.includes('road')) {
            return 'truck'
        } else {
            return fallback || 'truck'
        }
    }

    public setTransportContext(transportTypeEditor: ITransportTypeEditor) {
        this.context.dispatch({ type: TransportActionType.SetTransportContext, payload: transportTypeEditor })
    }

    public unsetTransportContext() {
        if (this.context.stores.transport?.transportTypeId || this.context.stores.transport?.instanceId) {
            this.context.dispatch({ type: TransportActionType.UnsetTransportContext })
        }
    }

    public setTransportType(transportType?: TransportType) {
        const focus = !transportType?.uuid ? 'name' : undefined
        this.setTransportContext({
            ...this.context.stores.transport,
            transportTypeId: transportType?.uuid || TransportService.transportNewId,
            focus: focus,
        })
    }

    private updateContext(tts?: TransportType[]) {
        tts?.forEach((tt) => {
            if (tt.uuid) {
                tt.summary = TransportService.getTransportTypeSummary(tt)
                TransportService.transportTypeById.set(tt.uuid, tt)
            }
        })
        TransportService.transportTypes = Array.from(TransportService.transportTypeById.values())
        this.context.dispatch({ type: TransportActionType.SetTransportTypesUpdated })
        setTimeout(() => {
            const transportInventory = TransportService.transportTypes.map((t) =>
                InventoryService.transportToInventory(t),
            )
            this.context.dispatch({ type: InventoryActionType.Merge, payload: transportInventory })
        }, 10)
    }

    public async getTransportTypes(queryString: string = '', refreshCache: boolean = false) {
        if (!refreshCache && TransportService.transportTypes?.length) {
            return TransportService.transportTypes
        }
        let url = this.basePath
        if (queryString) {
            url += `?${queryString}`
        }
        return await this.httpService.get<TransportType[]>(url).then((tts) => {
            this.updateContext(tts)
            return tts
        })
    }

    public async getTransportType(transportTypeId?: string): Promise<TransportType | undefined> {
        if (!transportTypeId) return undefined
        const _existing = TransportService.transportTypeById.get(transportTypeId)
        if (_existing) return _existing
        const tts = await this.getTransportTypes(undefined, true)
        return tts?.find((tt) => tt.uuid === transportTypeId)
    }

    public async saveTransportType(transportType: TransportType): Promise<TransportType> {
        return this.httpService
            .post<TransportType>(this.basePath, { body: JSON.stringify({ transportType }) })
            .then((tt) => {
                this.updateContext([tt])
                if (!transportType?.uuid && tt?.uuid) {
                    // refresh main list if it's a new transport type
                    this.getTransportTypes(undefined, true)
                }
                if (this.context.stores.transport.transportTypeId === TransportService.transportNewId) {
                    this.context.dispatch({ type: TransportActionType.SetTransportTypeId, payload: tt.uuid })
                }
                return tt
            })
    }

    public async saveTransportSegment(transportType: TransportType, segment: TransportSegment): Promise<TransportType> {
        if (!transportType.uuid && !segment.uuid) {
            transportType = await this.saveTransportType(transportType)
            segment.uuid = transportType.segments?.[0]?.uuid
        }
        return this.httpService
            .put<TransportType>(`${this.basePath}/${transportType.uuid}/segment`, {
                body: JSON.stringify({ segment }),
            })
            .then((tt) => {
                this.updateContext([tt])
                return tt
            })
    }

    public async deleteTransportSegment(
        transportType: TransportType,
        segment: TransportSegment,
    ): Promise<TransportType> {
        return this.httpService
            .delete<TransportType>(`${this.basePath}/${transportType.uuid}/segment/${segment.uuid}`)
            .then((tt) => {
                this.updateContext([tt])
                return tt
            })
    }

    public async deleteTransportType(transportType: TransportType): Promise<void> {
        await this.httpService
            .delete(`${this.basePath}/${transportType.uuid}`)
            .then(() => {
                if (transportType.uuid) {
                    TransportService.transportTypeById.delete(transportType.uuid)
                    this.context.dispatch({ type: InventoryActionType.RemoveById, payload: transportType.uuid })
                }
                this.context.dispatch({ type: TransportActionType.UnsetTransportType })
                this.getTransportTypes(undefined, true)
            })
            .catch(Utils.errorToast)
    }

    public async getTransportInstance(
        transportInstance: TransportInstance,
        nodeId: string,
        cacheOk: boolean = false,
    ): Promise<TransportInstance> {
        if (cacheOk && transportInstance?.uuid) {
            const cached = TransportService.transportInstanceById.get(transportInstance.uuid)
            if (cached) return Promise.resolve(cached)
        }
        return this.httpService
            .get<TransportInstance>(`${this.basePath}/instance/${transportInstance.uuid}?nodeId=${nodeId}`)
            .then((ti) => {
                this.updateTransportInstanceContext([ti])
                return ti
            })
    }

    public async updateTransportInstance(
        transportInstance: TransportInstance,
        nodeId: string,
    ): Promise<TransportInstance> {
        return this.httpService
            .put<TransportInstance>(`${this.basePath}/instance`, {
                body: JSON.stringify({ nodeId: nodeId, transportInstance: transportInstance }),
            })
            .then((ti) => {
                this.updateTransportInstanceContext([ti])
                if (this.context.stores.transport.instanceId === TransportService.transportNewId) {
                    this.context.dispatch({ type: TransportActionType.SetTransportInstanceId, payload: ti.uuid })
                }
                return ti
            })
    }

    public async deleteTransportInstance(transportInstance: TransportInstance): Promise<void> {
        await this.httpService.delete(`${this.basePath}/instance/${transportInstance.uuid}`).catch(Utils.errorToast)
        this.context.dispatch({ type: TransportActionType.DeleteTransportInstance, payload: transportInstance })
    }

    public async setAverages(): Promise<GenericObject> {
        return this.httpService.post<GenericObject>(this.basePath, { body: JSON.stringify({ averages: true }) })
    }
}
