import { CSSProperties, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { UploadIcon } from '../Icons/UploadIcon'
import Utils from '../../services/utils'
import Spinner from '../Spinner'
import { allowedImageFileTypes } from '../../services/file'
import { ND } from '../../types'
import { Img } from '../Img'
import { VariableServicesContext } from '../../services'

const MAX_MB = 5

export const ImageUploader = (props: {
    nodeType: ND
    nodeId?: string
    filePath?: string
    shouldBeSquare?: boolean
    className?: string
    imageClassName?: string
    style?: CSSProperties
    previewIcon?: ReactNode
    iconSize?: number
    disabled?: boolean
    onUpload: (filePath: string) => void
}) => {
    const { fileService } = useContext(VariableServicesContext)
    const [file, setFile] = useState<File>()
    const [preview, setPreview] = useState<string>()
    const [savingImage, setSavingImage] = useState<boolean>(false)
    const fileReader = new FileReader()

    const previewIcon: ReactNode = useMemo(
        () => props.previewIcon || <UploadIcon size={props.iconSize || 40} />,
        [props.previewIcon, props.iconSize],
    )

    useEffect(() => {
        if (props.filePath) {
            setPreview(Utils.getFullImageUrl(props.filePath))
        }
    }, [props.filePath])

    useEffect(() => {
        if (!file) {
            return
        }
        const fileNameParts = file.name.split('.')
        const fileType = fileNameParts[fileNameParts.length - 1]
        if (!allowedImageFileTypes.includes(fileType?.toLowerCase())) {
            Utils.errorToast(`${fileType} files are not allowed`)
            return
        }
        if (file.size > Utils.ONE_MB * MAX_MB) {
            Utils.errorToast(`Images cannot be larger than ${MAX_MB}mb`)
            return
        } else {
            fileReader.readAsDataURL(file)
        }
    }, [file])

    fileReader.onload = async () => {
        const testImg = new Image()
        testImg.onload = () => {
            const [, diff] = Utils.percentOfTotal(testImg.width, testImg.height)
            if (props.shouldBeSquare && Math.abs(100 - diff) > 5) {
                Utils.errorToast('Images should be square')
            } else if (file && props.nodeId) {
                setSavingImage(true)
                fileService
                    .uploadImage(props.nodeType, props.nodeId, file)
                    .then((fileData) => props.onUpload(fileData.path))
                    .catch(Utils.errorToast)
                    .finally(() => setSavingImage(false))
            }
        }
        testImg.src = fileReader.result as string
    }

    return (
        <label
            className={[
                props.className,
                props.nodeId ? '' : 'opacity-25 not-clickable',
                props.filePath ? 'align-items-start' : 'bg-light align-items-center',
                'd-flex justify-content-center clickable position-relative',
                !preview ? 'rounded-2' : '',
            ].join(' ')}
            style={props.style}
        >
            <input
                disabled={props.disabled}
                className='fill-parent visibility-hidden clickable'
                type='file'
                onClick={(e) => {
                    if (!props.nodeId) {
                        e.preventDefault()
                        e.stopPropagation()
                    }
                }}
                onChange={(e) => {
                    if (e.target.files && e.target.files.length === 1) {
                        setFile(e.target.files[0])
                    }
                }}
            />
            <div className='mt-1'>{!preview && previewIcon}</div>
            <Img className={props.imageClassName} data={preview} alt='' />
            <div hidden={!savingImage} className='fill-parent bg-white bg-opacity-25'>
                <div className='fill-parent d-flex align-items-center justify-content-center'>
                    <Spinner />
                </div>
            </div>
        </label>
    )
}
