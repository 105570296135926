import { Handle, NodeProps, Position } from 'reactflow'
import ProductService, { ProductFootprintType } from '../../services/product'
import { Img } from '../Img'
import CO2e from '../CO2e'
import { ConnectionStatus } from '../Icons/ConnectionStatus'
import { ArrowCircleLeft, ArrowCircleRight } from '@phosphor-icons/react'
import { FootprintFlowActionType, INPUT_NODE_HEIGHT, SOURCE_PRODUCT_NODE_WIDTH } from '../../services/flow'
import Button from '../Input/Button'
import { useContext, useMemo } from 'react'
import { ApplicationContext } from '../../context'
import { VariableServicesContext } from '../../services'
import { AddSourceProduct } from '../Part/AddSourceProduct'
import { PartType } from '../../services/part'
import { ProductIcon } from '../Icons/ProductIcon'
import { InventoryIcon } from '../Icons/InventoryIcon'
import Utils from '../../services/utils'
import { useProduct } from '../../hooks/useProduct'
import { useInput } from '../../hooks/useInput'
import { ND } from '../../types'

export const SourceProductNode = ({ id, data }: NodeProps) => {
    const context = useContext(ApplicationContext)
    const { flowService, inputService, partService, productService } = useContext(VariableServicesContext)

    const input = useInput({ inputId: data.input?.uuid })
    const sourceProduct = useProduct({ product: input?.sourceProduct || data.sourceProduct })

    const isDownstream = useMemo(() => input?.useStageCategory?.type === 'Downstream', [input?.useStageCategory?.type])

    const canExpand = useMemo(
        () => input?.sourceProduct?.uuid === sourceProduct?.uuid && sourceProduct?.type === ProductFootprintType.LIVE,
        [input?.sourceProduct?.uuid, sourceProduct?.uuid, sourceProduct?.type],
    )

    const expand = async () => {
        await flowService.getNodesAndEdges({
            product: sourceProduct,
            parentProduct: sourceProduct,
            parentInput: input,
            recursive: false,
        })
    }

    if (!input) return null

    let className = 'border border-primary border-dashed rounded-2 bg-white small overflow-hidden'
    let content = (
        <AddSourceProduct
            targetType={ND.Input}
            node={input}
            label={
                <>
                    <ProductIcon /> Add {ProductService.elementTitle()}
                </>
            }
            className='d-flex align-items-center justify-content-center h-100 w-100'
            btnClassName='d-block w-100 p-2 text-center rounded-1'
            btnActiveClassName=' '
            btnHoverClassName='bg-light-hover'
            onDone={(sp, part) => {
                let sourceProduct = part?.type === PartType.MIX ? undefined : sp
                inputService
                    .updateInput({
                        uuid: input.uuid,
                        part,
                        sourceProduct,
                    })
                    .then(() => context.dispatch({ type: FootprintFlowActionType.Refresh }))
            }}
        />
    )

    if (sourceProduct?.uuid) {
        className = 'border border-primary rounded-2 px-2 py-1 bg-white bg-light-hover small overflow-hidden'
        content = (
            <div className='hover-parent'>
                {canExpand && (
                    <Button
                        className='btn btn-plain position-absolute top-50 translate-middle-y show-on-hover bg-white z-index-popover rounded-circle'
                        style={isDownstream ? { right: '-1rem' } : { left: '-1rem' }}
                        onClick={expand}
                    >
                        {isDownstream && <ArrowCircleRight />}
                        {!isDownstream && <ArrowCircleLeft />}
                    </Button>
                )}
                <Button
                    className='btn btn-plain d-flex align-items-center gap-2 w-100'
                    onClick={() => {
                        if (input.part?.uuid) {
                            partService.openPartEditor(input.part, input?.uuid)
                        } else if (canExpand) {
                            expand().then()
                        } else if (sourceProduct?.uuid) {
                            productService.openPreview(sourceProduct)
                        }
                    }}
                >
                    <span className='flex-shrink-0'>
                        <Img
                            src={sourceProduct?.productImageUrl}
                            size='40px'
                            placeholderIcon={<InventoryIcon size={Utils.largeIconSize} />}
                        />
                    </span>
                    <div className='fs-base flex-grow-1 overflow-hidden text-start'>
                        <div className='text-overflow-ellipsis' title={ProductService.getProductName(sourceProduct)}>
                            {ProductService.getProductName(sourceProduct)}
                        </div>
                        {sourceProduct?.productOf?.uuid && (
                            <div className='d-flex align-items-baseline'>
                                {sourceProduct?.productOf?.uuid !== context.stores.company?.uuid && (
                                    <ConnectionStatus company={sourceProduct?.productOf} className='me-1' />
                                )}
                                <div className='text-overflow-ellipsis small text-muted'>
                                    {sourceProduct?.productOf?.name}
                                </div>
                            </div>
                        )}
                    </div>
                    <CO2e
                        product={sourceProduct}
                        co2e={sourceProduct.upstreamCo2e || sourceProduct?.co2e}
                        unitSize='small'
                        className='text-end'
                        unitsClassName='d-block small text-muted text-end'
                    />
                </Button>
            </div>
        )
    }

    return (
        <div
            className={className}
            style={{ width: `${SOURCE_PRODUCT_NODE_WIDTH}px`, height: `${INPUT_NODE_HEIGHT}px` }}
        >
            {content}
            <Handle type='target' position={Position.Left} id={id} />
            <Handle type='source' position={Position.Right} id={id} />
        </div>
    )
}
