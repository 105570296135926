import UnitService, { Unit, UnitType } from './unit'
import { Company } from './company'
import { DataQuality, Product } from './product'
import { Calculation } from './calculator'
import { Part } from './part'
import { Amount, VariableBaseNode } from '../types'
import { CategoryModelInstance } from './category-model'
import VariableService from './service'
import ProcessingService, { ProcessingType } from './processing'
import TransportService, { TransportInstance } from './transport'
import UseStageService, { UseStageCategory, UseStageType } from './useStage'
import { UIOptionActionType } from './ui'
import Utils from './utils'
import { ActionMap } from '../context'

export type InputType = 'input' | 'transport' | 'processing' | 'use-stage' | 'model' | 'ai'

export interface Input extends VariableBaseNode {
    useStageCategory?: UseStageCategory
    quantity: number
    order: number
    circular?: number
    product?: Product
    unit?: Unit
    weight?: Amount
    sourceProduct?: Product | null
    part?: Part | null
    factor?: Product
    supplier?: Company
    calculation?: Calculation
    modelType?: CategoryModelInstance | null

    transportInstance?: TransportInstance | null
    transportFor?: Input
    transportedVia?: Input

    processingType?: ProcessingType | null
    processingFor?: Input
    processedVia?: Input

    useStageType?: UseStageType | null

    quality?: DataQuality
    co2e?: string
}

export interface InputUseStageSummary {
    category: string
    name?: string
    code?: string
    amount: number
    byproductCo2e: number
}

export interface InputContext {
    instanceId?: string
    currentId?: string
    action?: 'create-part' | 'edit'
    updatedIds?: string[]
    updates?: number
    deletedInputId?: string
}

export const defaultInputContext: InputContext = {
    instanceId: undefined,
    currentId: undefined,
    action: undefined,
    updatedIds: [],
    updates: 0,
    deletedInputId: undefined,
}

export enum InputActionType {
    SetInputId = 'SetInputId',
    DeletedInputID = 'DeletedInputID',
    CloseInput = 'CloseProductInput',
    CreatePartForInput = 'CreatePartForInput',
    DeselectPartForInput = 'DeselectPartForInput',
    UpdateInput = 'UpdateProductInput',
}

type InputActionPayload = {
    [InputActionType.CreatePartForInput]: InputContext
    [InputActionType.DeselectPartForInput]: undefined
    [InputActionType.SetInputId]: string | undefined
    [InputActionType.DeletedInputID]: string
    [InputActionType.CloseInput]: undefined
    [InputActionType.UpdateInput]: Input[] | undefined
}

export type InputActions = ActionMap<InputActionPayload>[keyof ActionMap<InputActionPayload>]

export const InputReducer = (state: InputContext, action: InputActions): InputContext => {
    switch (action.type) {
        case InputActionType.CreatePartForInput:
            return { ...state, ...action.payload, action: 'create-part', updates: 0 }
        case InputActionType.DeselectPartForInput:
            return { ...defaultInputContext }
        case InputActionType.SetInputId:
            return { ...state, currentId: action.payload, action: 'edit', updates: 0 }
        case InputActionType.DeletedInputID:
            return { ...defaultInputContext, deletedInputId: action.payload }
        case InputActionType.CloseInput:
            return { ...defaultInputContext }
        case InputActionType.UpdateInput:
            return {
                ...state,
                updatedIds: action.payload?.filter((i) => i.uuid)?.map((i) => i.uuid!),
                updates: (state?.updates || 0) + 1,
            }
        default:
            return state
    }
}

export default class InputService extends VariableService {
    private basePath: string = '/input'

    public static byId: Map<string, Input> = new Map<string, Input>()
    public static UseStageByCode: Map<string, UseStageCategory> = new Map<string, UseStageCategory>()

    public static getInputType(input?: Input): InputType {
        if (input?.processingType) {
            return 'processing'
        } else if (input?.transportInstance) {
            return 'transport'
        } else if (input?.useStageType) {
            return 'use-stage'
        } else if (input?.modelType) {
            return 'model'
        }
        return 'input'
    }

    public static getInputWeight(i: Input, sourceProduct?: Product): number {
        const sp = sourceProduct || i.sourceProduct
        let quantity = Utils.Decimal(sp?.weight?.quantity || 0)
        let unit = sp?.weight?.unit
        if (i.unit?.type === UnitType.WEIGHT) {
            quantity = Utils.Decimal(i.quantity)
            unit = i.unit
        } else {
            const itemQuantity = Utils.Decimal(i.quantity)
                .times(i.unit?.fromBaseUnit || 1)
                .times(sp?.unit?.toBaseUnit || 1)
            quantity = quantity.times(itemQuantity)
        }
        const conversionFactor = unit?.fromBaseUnit || 1
        return Utils.Decimal(quantity).times(conversionFactor).toNumber()
    }

    public static getTotalWeight(inputs: Input[]): number {
        return inputs
            .filter((i) => i.unit?.type && UnitService.unitsWithWeight.includes(i.unit?.type))
            .reduce((acc, i) => acc.plus(this.getInputWeight(i)), Utils.Decimal(0))
            .toNumber()
    }

    public getUseStages() {
        this.httpService.get<UseStageCategory[]>(`${this.basePath}/stage`).then((useStages) => {
            useStages.forEach((usc) => {
                usc.code && InputService.UseStageByCode.set(usc.code, usc)
            })
            this.context.dispatch({ type: UIOptionActionType.UseStagesReady, payload: true })
        })
    }

    public static updateContext(inputs: Input[]): void {
        inputs.forEach((input) => {
            if (input.uuid) InputService.byId.set(input.uuid, input)
            if (input.transportInstance) {
                if (input.transportFor?.uuid) {
                    const tf = InputService.byId.get(input.transportFor?.uuid || '')
                    if (tf?.uuid) InputService.byId.set(tf.uuid, { ...tf, transportedVia: input })
                }
                TransportService.updateTransportInstanceContext([input.transportInstance])
            }
            if (input.processingType) {
                if (input.processingFor?.uuid) {
                    const pf = InputService.byId.get(input.processingFor?.uuid || '')
                    if (pf?.uuid) InputService.byId.set(pf.uuid, { ...pf, processedVia: input })
                }
                ProcessingService.updateProcessingTypeContext([input.processingType])
            }
            if (input.useStageType) UseStageService.updateUseStageTypeContext([input.useStageType])
        })
    }

    private updateContext(inputs: Input[]): void {
        InputService.updateContext(inputs)
        this.context.dispatch({ type: InputActionType.UpdateInput, payload: inputs })
    }

    private patchContext(input: Partial<Input>): void {
        const cached = InputService.byId.get(input.uuid || '')
        if (cached) this.updateContext([{ ...cached, ...input }])
    }

    public async updateInput(input: Partial<Input>, clearInput?: boolean): Promise<Input> {
        let body = clearInput ? { clear: input } : { input: input }
        this.patchContext(input)
        return this.httpService
            .put<Input>(`${this.basePath}/${input.uuid}`, { body: JSON.stringify(body) })
            .then((input) => {
                this.updateContext([input])
                return input
            })
    }

    public updateInputOrdering(orderedInputs: Input[]): Promise<void> {
        return this.httpService.put<void>(this.basePath, {
            body: JSON.stringify({ orderedInputs }),
        })
    }

    public async duplicateInput(input: Input): Promise<Input> {
        return this.httpService.post<Input>(this.basePath, { body: JSON.stringify({ duplicate: input }) }).then((i) => {
            this.updateContext([i])
            return i
        })
    }

    public updateInputCalculation(calculation: Calculation, inputId: string): Promise<Input> {
        return this.httpService.put<Input>(`${this.basePath}/${inputId}/part`, {
            body: JSON.stringify({ calculation }),
        })
    }

    public getInputsBySourceProduct(sourceProduct: Product): Promise<Input[]> {
        return this.httpService.get<Input[]>(`${this.basePath}?sourceProductId=${sourceProduct.uuid}`)
    }

    public async getInput(inputId: string): Promise<Input> {
        return this.httpService.get<Input>(`${this.basePath}/${inputId}`).then((i) => {
            this.updateContext([i])
            return i
        })
    }

    public async removeInput(inputId?: string): Promise<Product> {
        if (!inputId) return Promise.reject('No input id provided')
        const existing = InputService.byId.get(inputId)
        return this.httpService.delete<Product>(`${this.basePath}/${inputId}`).then((p) => {
            this.context.dispatch({ type: InputActionType.DeletedInputID, payload: inputId })
            setTimeout(() => this.context.dispatch({ type: InputActionType.CloseInput }), 100)
            InputService.byId.delete(inputId)
            if (existing?.transportFor?.uuid) {
                const tf = InputService.byId.get(existing.transportFor?.uuid || '')
                if (tf?.uuid) InputService.byId.set(tf.uuid, { ...tf, transportedVia: undefined })
            }
            if (existing?.processingFor?.uuid) {
                const pf = InputService.byId.get(existing.processingFor?.uuid || '')
                if (pf?.uuid) InputService.byId.set(pf.uuid, { ...pf, processedVia: undefined })
            }
            this.updateContext([])
            return p
        })
    }
}
