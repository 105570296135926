import VariableService from './service'
import { Amount, ND } from '../types'
import { UIOptionActionType } from './ui'
import Utils from './utils'
import { ExchangeToken } from './token'
import { Unit } from './unit'
import { Product } from './product'
import { CompanyPerspective } from './company'

export enum ExchangeStatus {
    Draft = 'draft',
    Requested = 'requested',
    Opened = 'opened',
    In_Progress = 'in_progress',
    Approved = 'approved',
    Updated = 'updated',
    Discarded = 'discarded',
}

export default class ExchangeService extends VariableService {
    private basePath: string = '/exchange'
    public static webRoot: string = '/exchange'
    public static webTitle = (): string => 'Exchange'

    public static byId: Map<string, ExchangeToken> = new Map()
    public static byNodeId: Map<string, ExchangeToken> = new Map()

    public static getStatus(status?: ExchangeStatus, perspective?: CompanyPerspective): string {
        if (perspective === 'customer') {
            if (status === ExchangeStatus.Approved) return 'Received'
        }
        switch (status) {
            case ExchangeStatus.Draft:
                return 'Draft'
            case ExchangeStatus.Requested:
                return 'Requested'
            case ExchangeStatus.Opened:
                return 'Opened'
            case ExchangeStatus.In_Progress:
                return 'In progress'
            case ExchangeStatus.Approved:
                return 'Approved'
            case ExchangeStatus.Updated:
                return 'Updated'
            case ExchangeStatus.Discarded:
                return 'Discarded'
            default:
                return 'Unknown'
        }
    }

    public setExchangeId(exchangeId?: string): void {
        this.context.dispatch({ type: UIOptionActionType.SetExchangeId, payload: exchangeId })
    }

    public setConversionId(exchangeId?: string): void {
        this.context.dispatch({ type: UIOptionActionType.SetConversionId, payload: exchangeId })
    }

    public setRequestIds(requestIds?: string[]): void {
        this.context.dispatch({ type: UIOptionActionType.SetExchangeRequestIds, payload: requestIds })
    }

    public updateContext(exchangeTokens: ExchangeToken[]): void {
        exchangeTokens.forEach((ei) => {
            if (ei.uuid) ExchangeService.byId.set(ei.uuid, ei)
            if (ei.node.uuid) ExchangeService.byNodeId.set(ei.node.uuid, ei)
        })
        this.context.dispatch({ type: UIOptionActionType.ExchangeUpdated })
    }

    public get(): void {
        this.httpService.get<ExchangeToken[]>(this.basePath).then((ei) => {
            this.updateContext(ei)
            return ei
        })
    }

    public async getRequested(token: string): Promise<ExchangeToken[]> {
        return this.httpService.get<ExchangeToken[]>(`${this.basePath}?type=requested&token=${token}`).then((ei) => {
            this.updateContext(ei)
            return ei
        })
    }

    public addToExchange(nodeId: string, nodeType: ND): void {
        this.httpService
            .post<ExchangeToken[]>(this.basePath, { body: JSON.stringify({ nodeId, nodeType }) })
            .then((ei) => this.updateContext(ei))
            .then(() => Utils.successToast('Added to Exchange'))
    }

    public async setStatus(exchangeId: string, status: ExchangeStatus): Promise<void> {
        return this.httpService.put(`${this.basePath}/${exchangeId}`, { body: JSON.stringify({ status }) })
    }

    public async setSource(exchangeId?: string, sourceId?: string): Promise<void> {
        if (!exchangeId || !sourceId) return
        return this.httpService.put(`${this.basePath}/${exchangeId}`, { body: JSON.stringify({ source: sourceId }) })
    }

    public async setUploaded(exchangeId?: string, co2e?: string, unit?: Unit): Promise<void> {
        if (!exchangeId || !co2e || !unit) return
        return this.httpService.put(`${this.basePath}/${exchangeId}`, {
            body: JSON.stringify({ upload: true, co2e, unitCode: unit.code }),
        })
    }

    public async requestFootprints(requestIds?: string[]): Promise<void> {
        if (!requestIds) return
        return this.httpService.post(this.basePath, { body: JSON.stringify({ requestIds }) })
    }

    public async setPact(exchangeId?: string, pactUrl?: string): Promise<Product[]> {
        if (!exchangeId || !pactUrl) return []
        return this.httpService.put<Product[]>(`${this.basePath}/${exchangeId}`, { body: JSON.stringify({ pactUrl }) })
    }

    public async setConversion(exchangeId?: string, conversion?: Amount): Promise<void> {
        if (!exchangeId || !conversion) return
        return this.httpService.put(`${this.basePath}/${exchangeId}`, { body: JSON.stringify({ conversion }) })
    }

    public removeFromExchange(nodeId?: string): void {
        this.httpService
            .delete(`${this.basePath}/${nodeId}`)
            .then(() => {
                if (nodeId) {
                    const existing = ExchangeService.byNodeId.get(nodeId)
                    ExchangeService.byNodeId.delete(nodeId)
                    if (existing?.uuid) ExchangeService.byId.delete(existing.uuid)
                }
                this.context.dispatch({ type: UIOptionActionType.ExchangeUpdated })
            })
            .then(() => Utils.successToast('Removed from Exchange'))
    }
}
