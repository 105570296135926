import { KeyValuePair } from '../../types'
import { Airplane, Boat, Snowflake, ThermometerCold, TrainSimple, Truck } from '@phosphor-icons/react'

type TransportationType =
    | 'air-medium'
    | 'air-very-short'
    | 'air-short'
    | 'air-long'
    | 'air-cooling'
    | 'air-freezing'
    | 'container-ship'
    | 'container-ship-cooling'
    | 'container-ship-freezing'
    | 'ferry'
    | 'inland-waterways-barge'
    | 'inland-waterways-tanker'
    | 'inland-waterways-tanker-cooling'
    | 'inland-waterways-tanker-freezing'
    | 'tanker'
    | 'tanker-petroleum'
    | 'tanker-lng'
    | 'tanker-other-liquid'
    | 'tanker-dry-goods'
    | 'train'
    | 'train-cooling'
    | 'train-freezing'
    | 'truck'

export const transportationTypes: KeyValuePair<TransportationType>[] = [
    {
        icon: <Airplane />,
        name: 'Air',
        value: 'air-medium',
        description: 'DEFRA',
        metaData: { premium: false },
        node: { name: '', syncId: 'recM9axUlIsAGFRIb' }, // 0.54 kgCO2e
    },
    // {
    //     icon: <Airplane />,
    //     name: 'Air Very Short Haul (< 800 km)',
    //     value: 'air-very-short',
    //     description: 'DEFRA',
    //     metaData: { premium: false },
    //     node: { name: '', syncId: 'recTmP3kCjb1ZSB1j' }, // 2.38 kgCO2e
    // },
    {
        icon: <Airplane />,
        name: 'Air Very Short Haul (< 800 km)',
        value: 'air-very-short',
        description: 'ecoinvent',
        metaData: { premium: true },
        node: { name: '', syncId: 'recOkeqDIk5RKTYPa' }, // 1.01 kgCO2e
    },
    // {
    //     icon: <Airplane />,
    //     name: 'Air Short Haul (800 - 1500 km)',
    //     value: 'air-short',
    //     description: 'DEFRA',
    //     metaData: { premium: false },
    //     node: { name: '', syncId: 'recT2gWsOMOlMksYU' }, // 1.22 kgCO2e
    // },
    {
        icon: <Airplane />,
        name: 'Air Short Haul (800 - 1500 km)',
        value: 'air-short',
        description: 'ecoinvent',
        metaData: { premium: true },
        node: { name: '', syncId: 'recjRkUoaxW2dtuBl' }, // 0.78 kgCO2e
    },
    {
        icon: <Airplane />,
        name: 'Air Medium Haul (1500 - 4000 km)',
        value: 'air-medium',
        description: 'ecoinvent',
        metaData: { premium: true },
        node: { name: '', syncId: 'recJ7GOmBpcGOQbUR' }, // 0.72 kgCO2e
    },
    // {
    //     icon: <Airplane />,
    //     name: 'Air Long Haul (> 4000 km)',
    //     value: 'air-long',
    //     description: 'DEFRA',
    //     metaData: { premium: false },
    //     node: { name: '', syncId: 'reccvgval5lpYvWqk' }, // 1.02 kgCO2e
    // },
    {
        icon: <Airplane />,
        name: 'Air Long Haul (> 4000 km)',
        value: 'air-long',
        description: 'ecoinvent',
        metaData: { premium: true },
        node: { name: '', syncId: 'recsZ2UN2rScDKitu' }, // 0.75 kgCO2e
    },
    {
        name: (
            <span>
                <Airplane /> Air <ThermometerCold /> cooling
            </span>
        ),
        text: 'Air (cooling)',
        value: 'air-cooling',
        description: 'ecoinvent',
        metaData: { premium: true },
        node: { name: '', syncId: 'recX1t3E5lLvjNsYa' }, // 0.75 kgCO2e
    },
    {
        name: (
            <span>
                <Airplane /> Air <Snowflake /> freezing
            </span>
        ),
        text: 'Air (freezing)',
        value: 'air-cooling',
        description: 'ecoinvent',
        metaData: { premium: true },
        node: { name: '', syncId: 'recPbCW7Y1kBpggo4' }, // 0.75 kgCO2e
    },
    {
        icon: <Boat />,
        name: 'Container ship',
        value: 'container-ship',
        description: 'DEFRA',
        metaData: { premium: false },
        node: { name: '', syncId: 'recyIMpN6fA4YjFrC' }, // 0.02 kgCO2e
    },
    // {
    //     icon: <Boat />,
    //     name: 'Container ship',
    //     value: 'container-ship',
    //     description: 'ecoinvent',
    //     metaData: { premium: true },
    //     node: { name: '', syncId: 'recR0AKMa0Ybuydr3' }, // 0.009 kgCO2e
    // },
    {
        name: (
            <span>
                <Boat /> Container ship <ThermometerCold /> cooling
            </span>
        ),
        text: 'Container ship (cooling)',
        value: 'container-ship-cooling',
        description: 'DEFRA',
        metaData: { premium: false },
        node: { name: '', syncId: 'recFDItQ2puZISfZ8' }, // 0.01 kgCO2e
    },
    // {
    //     name: (
    //         <span>
    //             <Boat /> Container ship{' '}
    //             <ThermometerCold /> cooling
    //         </span>
    //     ),
    //     text: 'Container ship (cooling)',
    //     value: 'container-ship-cooling',
    //     description: (
    //         <>
    //             ecoinvent <span className='premium-label'>Premium</span>
    //         </>
    //     ),
    //     metaData: { premium: true },
    //     node: { name: '', syncId: 'rec5KJV1oZhFgwiwo' }, // 0.02 kgCO2e
    // },
    {
        name: (
            <span>
                <Boat /> Container ship <Snowflake /> freezing
            </span>
        ),
        text: 'Container ship (freezing)',
        value: 'container-ship-freezing',
        description: 'ecoinvent',
        metaData: { premium: true },
        node: { name: '', syncId: 'rec9hNaGBJifZe77i' }, // 0.02 kgCO2e
    },
    {
        icon: <Boat />,
        name: 'Ferry',
        value: 'ferry',
        description: 'ecoinvent',
        metaData: { premium: true },
        node: { name: '', syncId: 'recISLZjmqxnS3c2V' }, // 0.11 kgCO2e
    },
    {
        icon: <Boat />,
        name: 'Inland waterways (Barge)',
        value: 'inland-waterways-barge',
        description: 'ecoinvent',
        metaData: { premium: true },
        node: { name: '', syncId: 'recS16T5IacznGQcl' }, // 0.05 kgCO2e
    },
    {
        icon: <Boat />,
        name: 'Inland waterways (Tanker)',
        value: 'inland-waterways-tanker',
        description: 'ecoinvent',
        metaData: { premium: true },
        node: { name: '', syncId: 'rec5eeI24AZNU1neF' }, // 0.04 kgCO2e
    },
    {
        name: (
            <span>
                <Boat /> Inland waterways (Tanker) <ThermometerCold /> cooling
            </span>
        ),
        text: 'Inland waterways (Tanker cooling)',
        value: 'inland-waterways-tanker-cooling',
        description: 'ecoinvent',
        metaData: { premium: true },
        node: { name: '', syncId: 'recL404XRBuOp6uzv' }, // 0.06 kgCO2e
    },
    {
        name: (
            <span>
                <Boat /> Inland waterways (Tanker) <Snowflake /> freezing
            </span>
        ),
        text: 'Inland waterways (Tanker freezing)',
        value: 'inland-waterways-tanker-freezing',
        description: 'ecoinvent',
        metaData: { premium: true },
        node: { name: '', syncId: 'recMIwYdZR7wK3al8' }, // 0.06 kgCO2e
    },
    {
        icon: <Boat />,
        name: 'Tanker',
        value: 'tanker',
        description: 'ecoinvent',
        metaData: { premium: true },
        node: { name: '', syncId: 'rect8BkKFVahNTajc' }, // 0.01 kgCO2e
    },
    {
        icon: <Boat />,
        name: 'Tanker (Petroleum)',
        value: 'tanker-petroleum',
        description: 'DEFRA',
        metaData: { premium: false },
        node: { name: '', syncId: 'recwBooZlZ40fGxSn' }, // 0.005 kgCO2e
    },
    // {
    //     icon: <Boat />,
    //     name: 'Tanker (Petroleum)',
    //     value: 'tanker-petroleum',
    //     description: (
    //         <>
    //             ecoinvent <span className='premium-label'>Premium</span>
    //         </>
    //     ),
    //     metaData: { premium: true },
    //     node: { name: '', syncId: 'recTtWnFNEDUukaDu' }, // 0.006 kgCO2e
    // },
    {
        icon: <Boat />,
        name: 'Tanker (LNG)',
        value: 'tanker-lng',
        description: 'DEFRA',
        metaData: { premium: false },
        node: { name: '', syncId: 'recxPLlg0QxyV9xeo' }, // 0.01 kgCO2e
    },
    // {
    //     icon: <Boat />,
    //     name: 'Tanker (LNG)',
    //     value: 'tanker-lng',
    //     description: (
    //         <>
    //             ecoinvent <span className='premium-label'>Premium</span>
    //         </>
    //     ),
    //     metaData: { premium: true },
    //     node: { name: '', syncId: 'recK5FMJmh59X6Sg0' }, // 0.01 kgCO2e
    // },
    // {
    //     icon: <Boat />,
    //     name: 'Tanker (Other liquid)',
    //     value: 'tanker-other-liquid',
    //     description: 'DEFRA',
    //     metaData: { premium: false },
    //     node: { name: '', syncId: 'rec7Eg015D2hjC0AQ' }, // 0.01 kgCO2e
    // },
    {
        icon: <Boat />,
        name: 'Tanker (Other liquid)',
        value: 'tanker-other-liquid',
        description: 'ecoinvent',
        metaData: { premium: true },
        node: { name: '', syncId: 'rec4d7fXAghUSplB2' }, // 0.007 kgCO2e
    },
    // {
    //     icon: <Boat />,
    //     name: 'Tanker (Dry goods)',
    //     value: 'tanker-dry-goods',
    //     description: 'DEFRA',
    //     metaData: { premium: false },
    //     node: { name: '', syncId: 'recnREmd7J6X2el4d' }, // 0.004 kgCO2e
    // },
    {
        icon: <Boat />,
        name: 'Tanker (Dry goods)',
        value: 'tanker-dry-goods',
        description: 'ecoinvent',
        metaData: { premium: true },
        node: { name: '', syncId: 'recr201ycgxdlhb2q' }, // 0.006 kgCO2e
    },
    {
        icon: <TrainSimple />,
        name: 'Train',
        value: 'train',
        description: 'DEFRA',
        metaData: { premium: false },
        node: { name: '', syncId: 'recpvxc82hMnkj3yF' }, // 0.03 kgCO2e
    },
    // {
    //     icon: <TrainSimple />,
    //     name: 'Train',
    //     value: 'train',
    //     description: 'ecoinvent',
    //     metaData: { premium: true },
    //     node: { name: '', syncId: 'recX2xxfoefUUcePM' }, // 0.05 kgCO2e
    // },
    {
        name: (
            <span>
                <TrainSimple /> Train <ThermometerCold /> cooling
            </span>
        ),
        text: 'Train (cooling)',
        value: 'train-cooling',
        description: 'ecoinvent',
        metaData: { premium: true },
        node: { name: '', syncId: 'rec8I57B2TVdjEM6p' }, // 0.05 kgCO2e
    },
    {
        name: (
            <span>
                <TrainSimple /> Train <Snowflake /> freezing
            </span>
        ),
        text: 'Train (freezing)',
        value: 'train-freezing',
        description: 'ecoinvent',
        metaData: { premium: true },
        node: { name: '', syncId: 'recuEbWggaO72HMxx' }, // 0.05 kgCO2e
    },
    {
        icon: <Truck />,
        name: 'Truck',
        value: 'truck',
        description: 'DEFRA',
        metaData: { premium: false },
        node: { name: '', syncId: 'recekOEUaShkeuQOW' }, // 0.11 kgCO2e
    },
    // {
    //     icon: <Truck />,
    //     name: 'Truck',
    //     value: 'truck',
    //     description: 'ecoinvent',
    //     metaData: { premium: true },
    //     node: { name: '', syncId: 'recMI5j7wobEwaABc' }, // 0.13 kgCO2e
    // },
]
