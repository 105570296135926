import { useCallback, useEffect, useState } from 'react'
import Utils from '../services/utils'
import { VariableNode } from '../types'

interface SaveStatus {
    uuid: string | undefined
    saving: boolean
    pending: any | undefined
}

const defaultSaveStatus: SaveStatus = {
    uuid: undefined,
    saving: false,
    pending: undefined,
}

const status = new Map<string, SaveStatus>()

export const useSave = (props: {
    instanceId?: string
    node?: VariableNode
    saveFn?: () => Promise<void>
    partialSaveFn?: (properties?: any) => Promise<any>
    onSuccess?: () => void
    onError?: () => void
}) => {
    const [saving, setSaving] = useState<boolean>(false)
    const [saved, setSaved] = useState<boolean>(false)
    const [shouldSave, setShouldSave] = useState<boolean>(false)
    const [instanceId, setInstanceId] = useState(props.instanceId || `rnd-${Math.random()}`)

    const save = useCallback(() => setShouldSave(true), [])

    useEffect(
        () => setInstanceId(props.instanceId || `rnd-${props.node?.uuid || Math.random()}`),
        [props.node?.uuid, props.instanceId],
    )

    useEffect(() => {
        if (shouldSave) {
            setSaving(true)
            props
                .saveFn?.()
                .then(props.onSuccess)
                .catch(props.onError || Utils.errorToast)
                .finally(() => {
                    setSaving(false)
                    Utils.setTrueFor(setSaved, 1500)
                })
            setShouldSave(false)
        }
    }, [shouldSave])

    const pSave = useCallback(
        async (properties?: any) => {
            const id = instanceId || ''
            const s = status.get(id) || { ...defaultSaveStatus, uuid: props.node?.uuid }
            if (s.saving) {
                s.pending = { ...s.pending, ...properties }
                status.set(id, s)
                return
            }
            s.saving = true
            status.set(id, s)
            setSaving(true)
            if (!s.uuid) {
                // save everything when there's no uuid yet
                properties = { ...props.node, ...properties }
            }
            // console.log(id, s.uuid, properties)
            return props
                .partialSaveFn?.({ uuid: s.uuid, ...properties })
                .then((n: any) => {
                    if (n?.uuid) s.uuid = n.uuid
                    status.set(id, s)
                    if (s.pending) {
                        s.saving = false
                        status.set(id, s)
                        pSave(s.pending)
                        s.pending = null
                        status.set(id, s)
                    }
                })
                .then(() => props.onSuccess?.())
                .catch(props.onError || Utils.errorToast)
                .finally(() => {
                    s.saving = false
                    status.set(id, s)
                    setSaving(false)
                    Utils.setTrueFor(setSaved, 1500)
                })
        },
        [instanceId, props.node, props.partialSaveFn, props.onSuccess, props.onError],
    )

    return { save, pSave, saving, setSaving, saved }
}
