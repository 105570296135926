import { UseStage } from '../Input/UseStage'
import UnitService from '../../services/unit'
import { Input } from '../../services/input'
import { ReactNode, useContext } from 'react'
import { VariableServicesContext } from '../../services'
import Button from '../Input/Button'
import { ModelSelectCell } from './ModelSelectCell'
import { AmountInput } from '../Input/Amount'
import { useInput } from '../../hooks/useInput'
import { useUseStageType } from '../../hooks/useUseStageType'
import { ND } from '../../types'

export const InputUseStage = (props: {
    input?: Input
    dragHandle: ReactNode
    disabled?: boolean
    onChange: (input: Input) => void
}) => {
    const { inputService, useStageService } = useContext(VariableServicesContext)

    const input = useInput({ input: props.input })
    const useStageType = useUseStageType({ useStageType: input?.useStageType })

    if (!input || !useStageType) return null

    return (
        <>
            <td className='align-middle'>{props.dragHandle}</td>
            <td className='align-middle small' style={{ paddingLeft: '.75rem' }}>
                <Button
                    className='btn p-0 fs-inherit text-inherit d-flex align-items-center gap-2 bg-light-hover text-start rounded-1'
                    onClick={() => {
                        if (!input) return
                        useStageService.editUseStageType({ node: input, focus: 'name' })
                    }}
                >
                    {useStageType?.name || input.name || 'Use stage'}
                </Button>
            </td>
            <td className='align-middle position-relative' style={{ maxWidth: '14rem' }}>
                <ModelSelectCell input={input} field='use-stage' />
                <UseStage
                    node={input}
                    nodeType={ND.Input}
                    startOpen={true}
                    useStageType={useStageType}
                    disabled={props.disabled}
                    onChange={(useStageType) => {
                        if (!input) return
                        inputService
                            .updateInput({
                                uuid: input.uuid,
                                quantity: useStageType.totalKWh || 0,
                                useStageType,
                                unit: UnitService.unitByCode['kWh'],
                            })
                            .then((ui) => props.onChange({ ...input, ...ui, useStageType }))
                    }}
                />
            </td>
            <td />
            <td style={{ width: '5rem' }} className='align-middle position-relative'>
                <AmountInput
                    amount={{ quantity: input.quantity, unit: input.unit }}
                    disabled={true}
                    inputFieldProps={{ isNumber: true, required: true, extraClassName: 'bg-transparent' }}
                    prettyNumberProps={{ showZeroesToNumber: true }}
                    onChange={() => {}}
                />
            </td>
            <td />
        </>
    )
}
