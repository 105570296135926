import { Amount, ND, VariableBaseNode, VariableNode } from '../types'
import { GeoLocation } from './geoLocation'
import { Product } from './product'
import VariableService from './service'
import UnitService from './unit'
import { Location } from './location'
import { Input } from './input'
import { ActionMap } from '../context'

export interface UseStage extends VariableBaseNode {
    name: string
    code: string
    label: string
}

export enum UseStageCategoryType {
    UPSTREAM = 'Upstream',
    DIRECT = 'Direct',
    DOWNSTREAM = 'Downstream',
}

export interface UseStageCategory extends VariableBaseNode {
    name: string
    code: string
    type: UseStageCategoryType
    useStage: UseStage
}

export type UseStageTypeMethod = 'static' | 'use-frequency-duration'

export interface UseStageType extends VariableBaseNode {
    totalKWh?: number
    co2e?: string
    method?: UseStageTypeMethod
    totalEnergy?: Amount
    energyPerUse?: Amount
    useFrequency?: Amount
    timeInUse?: Amount
    geoLocation?: GeoLocation | null
    location?: Location | null
    footprint?: Product
}

export interface IUseStageEditor {
    useStageId?: string
    node?: VariableNode
    nodeType?: ND
    focus?: string
    updates?: number
    deletedUseStageTypeId?: string
}

export enum UseStageActionType {
    SetUseStageType = 'SetUseStageType',
    SetUseStageId = 'SetUseStageId',
    UpdateUseStageType = 'UpdateUseStageType',
    UnsetUseStageType = 'UnsetUseStageType',
}

type UseStageTypeActionPayload = {
    [UseStageActionType.SetUseStageType]: IUseStageEditor
    [UseStageActionType.SetUseStageId]: string | undefined
    [UseStageActionType.UpdateUseStageType]: undefined
    [UseStageActionType.UnsetUseStageType]: undefined
}

export type UseStageTypeActions = ActionMap<UseStageTypeActionPayload>[keyof ActionMap<UseStageTypeActionPayload>]

export const UseStageTypeReducer = (state: IUseStageEditor, action: UseStageTypeActions): IUseStageEditor => {
    switch (action.type) {
        case UseStageActionType.SetUseStageType:
            return { ...action.payload, focus: action.payload.focus || undefined, updates: 0 }
        case UseStageActionType.SetUseStageId:
            return { ...state, useStageId: action.payload as string }
        case UseStageActionType.UpdateUseStageType:
            return { ...state, updates: state?.updates !== undefined ? state.updates + 1 : 0 }
        case UseStageActionType.UnsetUseStageType:
            return {
                ...state,
                useStageId: undefined,
                node: undefined,
                focus: undefined,
                deletedUseStageTypeId: undefined,
                updates: 0,
            }
        default:
            return state
    }
}

export default class UseStageService extends VariableService {
    private basePath: string = '/use-stage'

    public static byId: Map<string, UseStageType> = new Map()
    public static newId: string = 'new'

    public static updateUseStageTypeContext(useStageTypes: UseStageType[]): void {
        useStageTypes.forEach((ust) => {
            if (ust.uuid) {
                const existing = UseStageService.byId.get(ust.uuid)
                UseStageService.byId.set(ust.uuid, { ...existing, ...ust })
            }
        })
    }

    public updateUseStageTypeContext(useStageTypes: UseStageType[]): void {
        UseStageService.updateUseStageTypeContext(useStageTypes)
        this.context.dispatch({ type: UseStageActionType.UpdateUseStageType })
    }

    public static getEmptyUseStageType(): UseStageType {
        return {
            uuid: UseStageService.newId,
            method: 'use-frequency-duration',
            totalEnergy: { unit: UnitService.unitByCode['kWh'] },
            energyPerUse: { unit: UnitService.unitByCode['kWh'] },
            useFrequency: { unit: UnitService.unitByCode['d'] },
            timeInUse: { unit: UnitService.unitByCode['y'] },
        }
    }

    public editUseStageType(opts: { node: Input; nodeType?: ND; useStageType?: UseStageType; focus?: string }): void {
        if (this.context.stores.useStageType?.useStageId) return
        this.context.dispatch({
            type: UseStageActionType.SetUseStageType,
            payload: { ...opts, useStageId: opts.useStageType?.uuid || opts.node.useStageType?.uuid },
        })
    }

    public clearUseStageType(): void {
        this.context.dispatch({ type: UseStageActionType.UnsetUseStageType })
    }

    public async getUseStageType(useStageType: UseStageType, nodeId?: string): Promise<UseStageType> {
        return this.httpService
            .get<UseStageType>(`${this.basePath}/${useStageType.uuid}?nodeId=${nodeId}`)
            .then((ust) => {
                this.updateUseStageTypeContext([ust])
                return ust
            })
    }

    public async updateInputUseStageType(useStageType: UseStageType, nodeId: string): Promise<UseStageType> {
        return this.httpService
            .put<UseStageType>(this.basePath, { body: JSON.stringify({ nodeId, useStageType }) })
            .then((ust) => {
                this.updateUseStageTypeContext([ust])
                if (this.context.stores.useStageType.useStageId === UseStageService.newId) {
                    this.context.dispatch({ type: UseStageActionType.SetUseStageId, payload: ust.uuid })
                }
                return ust
            })
    }
}
