import { ButtonHTMLAttributes, CSSProperties, ReactNode, RefObject, useMemo } from 'react'
import Tooltip, { TooltipProps } from '../Tooltip'

export type ButtonProps = {
    id?: string
    passedRef?: RefObject<any>
    ariaLabel?: string
    element?: 'div' | 'span'
    children?: ReactNode
    spinner?: ReactNode
    className?: string
    tabIndex?: number
    style?: CSSProperties
    saving?: boolean
    unstyled?: boolean
    disabled?: boolean
    tooltip?: string
    tooltipProps?: Partial<TooltipProps>
    disabledTooltip?: ReactNode
    disabledTooltipProps?: Partial<TooltipProps>
    hidden?: boolean
    type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
    onClick?: (e: any) => void
    onMouseEnter?: (e: any) => void
    onMouseLeave?: (e: any) => void
    onDoubleClick?: (e: any) => void
}

const Button = (props: ButtonProps) => {
    const spinner = useMemo(() => {
        if (!props.saving) return null
        return props.spinner || <span className='spinner-border spinner-border-sm ms-1' />
    }, [props.saving, props.spinner])

    const className = useMemo(
        () =>
            [props.className || 'text-nowrap', props.element !== undefined && !props.disabled ? 'clickable' : ''].join(
                ' ',
            ),
        [props.className, props.element, props.disabled],
    )

    const elementProps = useMemo(
        () => ({
            id: props.id,
            tabIndex: props.tabIndex,
            className: className,
            style: props.style,
            hidden: props.hidden,
            disabled: props.disabled || props.saving,
            onClick: props.onClick,
            onDoubleClick: props.onDoubleClick,
            onMouseEnter: props.onMouseEnter,
            onMouseLeave: props.onMouseLeave,
        }),
        [
            props.id,
            props.tabIndex,
            className,
            props.style,
            props.hidden,
            props.disabled,
            props.saving,
            props.onClick,
            props.onDoubleClick,
            props.onMouseEnter,
            props.onMouseLeave,
        ],
    )

    if (props.disabled && props.disabledTooltip) {
        return (
            <Tooltip
                {...props.disabledTooltipProps}
                id={props.id}
                ariaLabel={props.ariaLabel}
                trigger='hover'
                className={`${className} disabled`}
                style={props.style}
                hidden={props.hidden}
                tooltipContent={props.disabledTooltip}
            >
                {props.children}
            </Tooltip>
        )
    }

    if (props.tooltip) {
        return (
            <Tooltip
                {...props.tooltipProps}
                trigger='hover'
                id={props.id}
                ariaLabel={props.ariaLabel}
                hidden={props.hidden}
                tooltipContent={props.tooltip}
                className={className}
                style={props.style}
                disabled={props.disabled || props.saving}
                onClick={props.onClick}
                onDoubleClick={props.onDoubleClick}
            >
                {props.children}
                {spinner}
            </Tooltip>
        )
    }

    if (props.element === 'div') {
        return (
            <div {...elementProps} aria-label={props.ariaLabel}>
                {props.children}
                {spinner}
            </div>
        )
    }

    if (props.element === 'span') {
        return (
            <span {...elementProps} aria-label={props.ariaLabel}>
                {props.children}
                {spinner}
            </span>
        )
    }

    return (
        <button ref={props.passedRef} type={props.type || 'button'} {...elementProps} aria-label={props.ariaLabel}>
            {props.children}
            {spinner}
        </button>
    )
}

export default Button
